import React from 'react'
import {
  Box,
  Paper,
  Divider,
  Typography as Typo,
  Button,
  TextField,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  OutlinedInput,
  Stack,
} from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Selection from '@learnerlife/components/common/Selection/Select'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Page from '@learnerlife/components/Page'

const validationSchema = yup.object({
  proposal_name: yup.string().required('Proposal name is required'),
  recipient: yup.string().required('Recipient name is required'),
  recipient_email: yup.string().required('Recipient email is required'),
})

const ProposalManage = () => {
  const formik = useFormik({
    initialValues: {
      // proposal_id: 'BS Computer Science',
      proposal_name: '',
      recipient: '',
      recipient_email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })
  const flexStyle = {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: '0px',
    padding: '0px',
    border: '0px',
    verticalAlign: 'top',
    width: '100%',
    margin: 0,
  }

  const computationStyle = {
    margin: '16px 0px 0px 0px',
    lineHeight: 1.5,
    fontSize: '1rem',
    fontWeight: 400,
  }

  const [status, setStatus] = React.useState('')
  const [dateCreate, setDateCreate] = React.useState<Dayjs | null>(null)
  const [dateDue, setDateDue] = React.useState<Dayjs | null>(null)

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string)
  }

  // const handleChangeDateCreate = (event: SelectChangeEvent) => {
  //   setDateCreate(event.target.value)
  // }

  const statusSelection = [
    'Fullstack Development',
    'Backend Development',
    'UI Design',
  ]

  return (
    <>
      <Page.Update parentPageName="Proposals" pageName="Create new proposal" />
      {/* Box Paper */}
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Paper variant="outlined" elevation={6}>
              {/*  inputs */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '24px',
                  backgroundColor: 'rgb(244, 246, 248)',
                }}
              >
                <Stack flex={1} direction="column">
                  <TextField
                    label="Proposal ID"
                    disabled
                    value="proposal-17099"
                    name="proposalId"
                    sx={{ ...flexStyle }}
                  />
                </Stack>
              </Box>
              {/* Programmes */}
              <Box sx={{ padding: '24px' }}>
                <Stack flex={1} direction="column">
                  <Typo
                    component="h6"
                    sx={{
                      fontWeight: 700,
                      lineHeight: 1.5,
                      color: 'rgb(145, 158, 171)',
                    }}
                  >
                    Proposal Details:{' '}
                  </Typo>
                  <TextField
                    label="Proposal For"
                    id="recipient"
                    name="recipient"
                    placeholder="eg. John Doe"
                    sx={{ ...flexStyle, marginTop: '1rem' }}
                  />
                  <TextField
                    label="Receipient's email"
                    id="recipient_email"
                    name="recipient_email"
                    placeholder="johndoe@gmail.com"
                    sx={{ ...flexStyle, marginTop: '1rem' }}
                  />
                </Stack>
                <Stack flex={1} direction="column" sx={{ marginTop: '2rem' }}>
                  <Typo
                    component="h6"
                    sx={{
                      margin: '0px 0px 24px',
                      fontWeight: 700,
                      lineHeight: 1.5,
                      color: 'rgb(145, 158, 171)',
                    }}
                  >
                    Programmes:{' '}
                  </Typo>
                  <Typo variant="body1">Programmes will be listed here</Typo>
                </Stack>
              </Box>
            </Paper>
          </Box>
          {/* Buttons */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '24px',
            }}
          >
            {/* <Button variant="contained" color="info">
          Save As Draft
        </Button> */}
            <Button
              type="submit"
              sx={{ marginLeft: '16px' }}
              variant="contained"
            >
              Create
            </Button>
          </Box>
        </form>
      </Box>
    </>
  )
}

export default ProposalManage
