import React from 'react'
import { Box, Typography as Typo, Card, Avatar } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useFirebaseApp } from '@learnerlife/contexts/FirebaseApp'
import Page from '@learnerlife/components/Page'
import Helpers from '@learnerlife/tools/helpers'
import type { TRoles } from '@learnerlife/tools/helpers'

const SProfileCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: 'center',
  // color: theme.palette.text.secondary,
  boxShadow: theme.customShadows.card,
  borderRadius: theme.shape.borderRadius,
  transition: 'translate .5s ease, box-shadow .5s ease',
  cursor: 'pointer',
  minHeight: '400px',
  position: 'relative',
  overflow: 'visible',
  marginTop: '2rem',
  // '&:hover': {
  //   translate: '0 -3%',
  //   boxShadow: theme.customShadows.cardHover,
  // },
  '.app-avatar': {
    alignSelf: 'center',
    marginTop: '-70px',
    marginBottom: '40px',
  },
}))

const Profile = () => {
  const { user } = useFirebaseApp()

  return (
    <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
      <Page.Update remove />
      {!!user?.currentUser && (
        <Box sx={{ maxWidth: '400px' }}>
          <SProfileCard>
            <Avatar
              className="app-avatar"
              alt={user?.currentUser?.displayName || ''}
              src={user?.currentUser?.photoURL || ''}
              sx={{ width: 100, height: 100 }}
            />
            <Typo variant="h3" color="CaptionText">
              Howdy 👋,
            </Typo>
            <Typo variant="h1"> {user?.currentUser?.displayName}!</Typo>
            <Typo variant="h4" color="GrayText">
              {' '}
              {Helpers.renderRole(user.roles as TRoles[])}
            </Typo>
            <Typo
              variant="body1"
              sx={{ marginTop: '2rem', textAlign: 'center' }}
            >
              Welcome back to Learner Life! <br />
              Profile details space here..
            </Typo>
          </SProfileCard>
        </Box>
      )}
    </Box>
  )
}

export default Profile
