import React from 'react'
import Box from '@mui/material/Box'
import { Typography as Typo, Link as MUILink } from '@mui/material'
import GoogleButton from 'react-google-button'

import { useFirebaseApp } from '../contexts/FirebaseApp'
import Link from '@learnerlife/components/common/Link'

const Signin = () => {
  const { actions } = useFirebaseApp()

  const handleGoogleSignIn = async () => {
    try {
      await actions.signin()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0,0,0,0.05)',
      }}
    >
      <Box
        textAlign="center"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 'auto',
          padding: theme.spacing(4),
          borderRadius: theme.shape.borderRadius / 6,
          boxShadow: theme.customShadows.card,
          background: 'white',
          '.app-logo': {
            lineHeight: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '90px',
            '& img': {
              height: '100%',
            },
          },
        })}
      >
        <figure className="app-logo">
          <img src="/images/ll_logo.png" alt="learner life logo" />
        </figure>
        <Typo variant="h2" sx={{ marginBottom: '0.1em' }}>
          Learner Life
        </Typo>
        <Typo variant="h4" sx={{ marginBottom: '1em' }} color="GrayText">
          Enhancing Life Through Learning
        </Typo>
        <GoogleButton
          onClick={handleGoogleSignIn}
          title="Continue with Google"
          label="Continue with Google"
        />
      </Box>
      <Box sx={{ marginTop: 'auto', padding: '1rem' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typo variant="body1" sx={{ marginBottom: '0.1em' }}>
            Don't have account?
          </Typo>
          <MUILink to="/contact-us" component={Link}>
            Contact us
          </MUILink>
        </Box>
      </Box>
    </Box>
  )
}

export default Signin
