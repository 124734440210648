import React from 'react'
import { Card, Box, Typography as Typo, TextField, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Unstable_Grid2'
import { useFormik } from 'formik'
import * as yup from 'yup'

import WyswygField from '@learnerlife/components/common/Wyswyg'
import FormDropzone from '@learnerlife/components/common/Form/FormDropzone'
import FormSwitch from '@learnerlife/components/common/Form/FormSwitch'

import Page from '@learnerlife/components/Page'

const validationSchema = yup.object({
  programme_name: yup.string().required('Programme name is required'),
  school_name: yup.string().required('School name is required'),
  description: yup.string().required('Description is required'),
  benefits: yup.string(),
})

const SFormColumn = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: theme.customShadows.card,
  borderRadius: theme.shape.borderRadius,
}))

const SColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const ProgrammeManage = () => {
  const formik = useFormik({
    initialValues: {
      programme_name: 'BS Computer Science',
      school_name: 'NTU',
      description: '',
      benefits: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })

  const handleDescriptionChange = (v: string) => {
    formik.setFieldValue('description', v)
  }

  return (
    <>
      <Page.Update
        parentPageName="Programmes"
        pageName="Create new programme"
      />
      <Box sx={{ height: '100vh' }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid md={8} xs={12}>
              <SFormColumn elevation={0}>
                <SColumnContainer>
                  <TextField
                    required
                    id="programme_name"
                    label="Programme Name"
                    defaultValue=""
                    placeholder=""
                    fullWidth
                  />
                  <WyswygField
                    value={formik.values.description}
                    onChange={handleDescriptionChange}
                    label="Description"
                    placeholder="Write something awesome.."
                  />

                  <WyswygField
                    value={formik.values.benefits}
                    onChange={handleDescriptionChange}
                    label="Benefits"
                    placeholder="Write or list down some benefits of your programme.."
                  />

                  <FormDropzone label="Photos and images" />
                </SColumnContainer>
              </SFormColumn>
            </Grid>
            <Grid md={4} xs={12}>
              <SColumnContainer>
                <SFormColumn elevation={0}>
                  <FormSwitch label="active" checked />
                </SFormColumn>
                <Button
                  variant="contained"
                  sx={{ marginTop: '1rem', height: '45px' }}
                >
                  Create Programme
                </Button>
              </SColumnContainer>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  )
}

export default ProgrammeManage
