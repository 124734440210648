import React from 'react'
import Box from '@mui/material/Box'
import { useAgent } from '..'
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import Table from '@learnerlife/components/Table'

import type { Data } from './Proposals'

const ProposalDetail = () => {
  const agent = useAgent()

  React.useEffect(() => {
    agent.actions.updatePageName('Detailed Proposals')
  }, [])
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const stock = ['In Stock', 'Low Stock', 'Out of Stock']
  const [personName, setPersonName] = React.useState<string[]>([])

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  return (
    <>
      <Box display="flex" justifyContent="end" mb={4}>
        <Button variant="contained" startIcon={<AddIcon />}>
          Add Details
        </Button>
      </Box>

      <Paper>
        <Box>
          <Box sx={{ padding: '24px 20px', flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {stock.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={personName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={8}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ padding: '24px 20px', flexGrow: 1 }}>
            <Table<Data> rows={[]} />
          </Box>
        </Box>
      </Paper>
    </>
  )
}

export default ProposalDetail
