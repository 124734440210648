import React from 'react'
import Box from '@mui/material/Box'

import { useSchool } from '.'

const SchoolDetail = () => {
  const school = useSchool()
  React.useEffect(() => {
    school.actions.updatePageName('School Detail')
  }, [])
  return <Box sx={{ height: '100vh' }}>School Detail</Box>
}

export default SchoolDetail
