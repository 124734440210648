import {
  Box,
  CircularProgress,
  Divider,
  Typography as Typo,
} from '@mui/material'
import React from 'react'
import { IStatusNode } from './types'

const StatusNode = ({
  icon,
  color,
  title,
  value,
  valueName,
  divider,
}: IStatusNode) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{ width: '100%', minWidth: '200px', textAlign: 'left' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
          sx={{
            '.fulltrace': {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 10,
            },
            '.MuiSvgIcon-root': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },
          }}
        >
          <CircularProgress
            color={color}
            value={value}
            variant="determinate"
            thickness={4}
            size="56px"
            sx={{
              opacity: 0.5,
            }}
          />
          <CircularProgress
            value={100}
            className="fulltrace"
            variant="determinate"
            thickness={4}
            size="56px"
            sx={{
              color: 'rgba(145, 158, 171, 0.16)',
              opacity: 0.5,
            }}
          />
          {icon}
          {/* <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typo variant="caption" component="div" color={color}>

            </Typo>
          </Box> */}
        </Box>
        <Box display="flex" flexDirection="column" m={2}>
          <Typo variant="h6"> {title} </Typo>
          <Typo variant="button">
            {' '}
            {value} <Typo variant="caption"> {valueName} </Typo>
          </Typo>
        </Box>
      </Box>

      {divider && (
        <Divider
          sx={{ borderStyle: 'dashed' }}
          orientation="vertical"
          flexItem
        ></Divider>
      )}
    </>
  )
}

export default StatusNode
