import React from 'react'
import { Box, Typography as Typo, Stack } from '@mui/material'

const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: ' center',
        alignItem: 'center',
      }}
    >
      <Stack direction="column" alignItems="flexStart" justifyContent="center">
        <Typo variant="h1">404</Typo>
        <Typo variant="h3">
          Oops, sorry, the page that you're looking for is not to be found.
        </Typo>
      </Stack>
    </Box>
  )
}

export default NotFound
