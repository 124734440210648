import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Typography,
  Tabs,
  Tab,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import AddIcon from '@mui/icons-material/Add'
import SendIcon from '@mui/icons-material/Send'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import DraftsIcon from '@mui/icons-material/Drafts'
import SearchIcon from '@mui/icons-material/Search'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import StatusNode from '@learnerlife/components/StatusNode'
import Table from '@learnerlife/components/Table'
import SpanIcon from '@learnerlife/components/SpanIcon'
import Page from '@learnerlife/components/Page'

import ProposalDetail from './ProposalDetail'
import ProposalManage from './ProposalManage'
import ProposalxStudent from './ProposalxStudent'

export interface Data {
  proposal_id: string
  client: string
  date: string
  name: string
  status: string
}

function createData(
  name: string,
  proposal_id: string,
  client: string,
  date: string,
  status: string,
): Data {
  return {
    name,
    proposal_id,
    client,
    date,
    status,
  }
}

const data = [
  createData(
    'Proposal for Jane Doe',
    '0001',
    'Jane Doe',
    '01 Jan 2023',
    'Sent',
  ),
  createData(
    'Proposal for John Doe',
    '0002',
    'John Doe',
    '01 Jan 2023',
    'Sent',
  ),
  createData(
    'Proposal for Stephen Curry',
    '0003',
    'Stephen Curry',
    '01 Jan 2023',
    'Sent',
  ),
  createData(
    'Proposal for Michael Jordan',
    '0004',
    'Michael Jordan',
    '01 Jan 2023',
    'Sent',
  ),
  createData(
    'Proposal for Lee Ji-eun',
    '0005',
    'Lee Ji-eun',
    '01 Jan 2023',
    'Sent',
  ),
  createData('Proposal for Rosee', '0006', 'Rosee', '01 Jan 2023', 'Sent'),
  createData('Proposal for Jang Uk', '0007', 'Jang Uk', '01 Jan 2023', 'Sent'),
  createData('Proposal for Pedro', '0008', 'Pedro', '01 Jan 2023', 'Sent'),
  createData(
    'Proposal for Elon Musk',
    '0009',
    'Elon Musk',
    '01 Jan 2023',
    'Sent',
  ),
  createData(
    'Proposal for Lebron James',
    '0010',
    'Lebron James',
    '01 Jan 2023',
    'Sent',
  ),
  createData(
    'Proposal for Jeremy Lin',
    '0011',
    'Jeremy Lin',
    '01 Jan 2023',
    'Sent',
  ),
]

const SPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  // boxShadow: theme.customShadows.card,
  boxShadow:
    'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
  borderRadius: '1rem',
  transition: 'translate .5s ease, box-shadow .5s ease',
  cursor: 'pointer',
  marginBottom: '1rem',
  border: 0,
}))

const Proposals = () => {
  // const agent = useAgent()

  // React.useEffect(() => {
  //   agent.actions.updatePageName('Student Proposals')
  // }, [])

  const [value, setValue] = React.useState('one')
  const [value1, setValue1] = React.useState<Dayjs | null>(null)
  const [value2, setValue2] = React.useState<Dayjs | null>(null)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const [age, setAge] = React.useState('')

  const handleChange2 = (event: SelectChangeEvent) => {
    setAge(event.target.value as string)
  }

  return (
    <>
      <Page.Update parentPageName="Proposals" pageName="List of proposals" />
      <Box display="flex" justifyContent="end" mb={4}>
        <Button
          component={RouterLink}
          to="/proposal/new"
          variant="contained"
          startIcon={<AddIcon />}
        >
          Create new proposal
        </Button>
      </Box>

      <SPaper variant="outlined" elevation={6}>
        <Box display="flex" flexDirection="row" pt={2} pb={2} borderRadius={2}>
          <StatusNode
            icon={<SendIcon />}
            color="primary"
            title="Sent"
            value={50}
            valueName="proposals"
            divider
          />
          <StatusNode
            icon={<EqualizerIcon color="info" />}
            color="info"
            title="Total"
            value={30}
            valueName="proposals"
            divider
          />
          <StatusNode
            icon={<DraftsIcon />}
            color="secondary"
            title="Draft"
            value={100}
            valueName="proposals"
          />
        </Box>
      </SPaper>
      <SPaper elevation={6} sx={{ overflow: 'hidden' }}>
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            // color="i"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            sx={{
              overflow: 'hidden',
              backgroundColor: 'rgb(244, 246, 248)',
              minHeight: '48px',
              '.MuiTab-root': {
                minHeight: '48px',
              },
            }}
          >
            <Tab
              icon={
                <SpanIcon
                  value={12}
                  textColor="rgb(27, 128, 106)"
                  bgColor="rgba(54, 179, 126, 0.16)"
                />
              }
              value="one"
              iconPosition="start"
              label="Sent"
            />
            <Tab
              icon={
                <SpanIcon
                  value={2}
                  textColor="rgb(0, 108, 156)"
                  bgColor="rgba(0, 184, 217, 0.16)"
                />
              }
              iconPosition="start"
              value="two"
              label="Total"
            />
            <Tab
              value="three"
              icon={<SpanIcon value={20} />}
              iconPosition="start"
              label="Draft"
            />
          </Tabs>
        </Box>
        <Box sx={{ padding: '24px 20px', flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item md={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  onChange={handleChange2}
                >
                  <MenuItem value={'a-z'}>A-Z</MenuItem>
                  <MenuItem value={'z-a'}>Z-A</MenuItem>
                  <MenuItem value={'newest'}>Newest</MenuItem>
                  <MenuItem value={'oldest'}>Oldest</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={value1}
                  onChange={(newValue) => {
                    setValue1(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={value2}
                  onChange={(newValue) => {
                    setValue2(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Search Proposal"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ paddingInline: '1.5rem', flexGrow: 1 }}>
          <Table<Data> rows={data} />
        </Box>
      </SPaper>
    </>
  )
}

Proposals.Detail = ProposalDetail
Proposals.Manage = ProposalManage
Proposals.Shared = ProposalxStudent

export default Proposals
