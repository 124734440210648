/* eslint-disable import/no-unresolved */
import { applyMiddleware, createStore, AnyAction } from 'redux'
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux'
import thunk, { ThunkDispatch, ThunkAction } from 'redux-thunk'

import reducers from './reducers'

const store = createStore(reducers, applyMiddleware(thunk))

export type AppDispatch = typeof store.dispatch
export type ReduxState = ReturnType<typeof reducers>
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>
export type TypedThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  AnyAction
>
export const useTypedDispatch = () => useDispatch<TypedDispatch>()
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector

export default store
