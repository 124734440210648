import React from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typo from '@mui/material/Typography'

import SchoolDetail from './SchoolDetail'

type TSchoolCtx = {
  actions: {
    updatePageName: (name: string) => void
  }
}

const SchoolContext = React.createContext({} as TSchoolCtx)

const School = () => {
  const [pageName, setPageName] = React.useState<string>('')
  const handlePageName = (name: string) => {
    setPageName(name)
  }
  return (
    <SchoolContext.Provider
      value={{
        actions: {
          updatePageName: handlePageName,
        },
      }}
    >
      <Box>
        <Box sx={{ marginBottom: '1rem', width: '100%' }}>
          <Typo variant="h4" fontWeight={400}>
            School
          </Typo>
          {pageName && <Typo variant="h3"> {pageName} </Typo>}
        </Box>
        <Outlet />
      </Box>
    </SchoolContext.Provider>
  )
}

export const useSchool = () => {
  const schoolCtx = React.useContext(SchoolContext)
  return schoolCtx
}

School.Detail = SchoolDetail

export default School
