import React from 'react'
import { alpha } from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import { ListItem, ListItemText, Box } from '@mui/material'
import * as icons from '@mui/icons-material'

import type { SxProps, Theme } from '@mui/material'

import type { TItem as TCollapsibleItem } from '../common/List/types'
import type { TIconKey } from '../common/Icon/types'
import List from '../common/List'
import Link from '../common/Link'

interface IItem {
  component: JSX.Element
  children?: JSX.Element
  className: string
  to: string
}
interface INavItem {
  children?: React.ReactNode
  to?: string
  icon?: TIconKey
  collapsible?: boolean
  items?: TCollapsibleItem[]
}

type TIcons = {
  [key: string]: keyof typeof icons
}

const iconsMap: TIcons = {
  dashboard: 'Dashboard',
  agent: 'AssignmentInd',
  school: 'School',
}

const NavItem = ({ children, icon, to = '/', items = [] }: INavItem) => {
  const collapsible = React.useMemo(() => Boolean(items?.length), [items])
  const renderIcon = () => {
    const Icon = icon && iconsMap[icon] ? icons[iconsMap[icon]] : null
    if (!Icon) {
      return null
    }
    return (
      <Box
        component="span"
        sx={{
          '& svg': { fontSize: (theme) => theme.typography.pxToRem(16.5) },
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          marginRight: 1.5,
          py: '2px',
        }}
      >
        <Icon fontSize="small" color={undefined} />
      </Box>
    )
  }

  function renderItem() {
    const sx = (theme: Theme) => ({
      borderRadius: theme.shape.borderRadius / 8,
      paddingInline: 2,
      width: '100%',
      '&.active, & .active': {
        color:
          theme.palette.mode === 'dark'
            ? theme.palette.primary.dark
            : theme.palette.primary.main,
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        '& svg, & .MuiListItemIcon-root svg': {
          color: 'primary.main',
        },
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              theme.palette.action.selectedOpacity,
            ),
          },
        },
        '&.Mui-focusVisible': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity +
              theme.palette.action.focusOpacity,
          ),
        },
      },
    })
    if (!collapsible) {
      return (
        <ListItemButton sx={sx} to={to} component={Link}>
          {renderIcon()}
          {children}
        </ListItemButton>
      )
    }
    return (
      <List.Collapsible icon={icon} items={items} sx={sx}>
        {children}
      </List.Collapsible>
    )
  }

  return (
    <ListItem
      sx={{
        padding: 0,
        marginBottom: 1,
        flexDirection: collapsible ? 'column' : 'row',
      }}
    >
      {renderItem()}
    </ListItem>
  )
}

export default NavItem
