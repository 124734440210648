import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Card,
  Box,
  Typography as Typo,
  Button,
  Stack,
  Snackbar,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { PageDefaultLayout } from '@learnerlife/components/common/Page'
import FormSelect from '@learnerlife/components/common/Form/FormSelect'

import { useTypedDispatch } from '@learnerlife/store'
import ProgrammesActions from '@learnerlife/store/actions/programmes.actions'

const Onboard = () => {
  const dispatch = useTypedDispatch()
  const [onboardType, setOnboardType] = React.useState<string>('')
  const [requesting, setRequesting] = React.useState<boolean>(false)
  const [requestInfo, setRequestInfo] = React.useState<{
    requestId: string
    requested: boolean
  }>({
    requestId: '',
    requested: false,
  })
  const [toast, setToast] = React.useState<{
    isOpen: boolean
    message: string
    type: string
  }>({
    isOpen: false,
    message: '',
    type: 'success',
  })

  const handleOnboardSelection = (selection: string) => {
    setOnboardType(selection)
  }

  const handleOnboardingRequest = () => {
    setRequesting(true)
    // Mock Onboarding request
    // TODO: API integration
    setTimeout(() => {
      setRequestInfo({
        requestId: '29384756',
        requested: true,
      })
      setRequesting(false)
      setToast({
        isOpen: true,
        message: "Yey! you've submitted a request for onboarding. ",
        type: 'success',
      })
    }, 1000)
  }

  const handleCloseToast = () => {
    setToast({
      isOpen: false,
      message: '',
      type: 'success',
    })
  }

  React.useEffect(() => {
    dispatch(ProgrammesActions.getProgrammes())
  }, [])

  return (
    <PageDefaultLayout>
      <Typo variant="h3">Onboarding</Typo>
      {requestInfo?.requested ? (
        <Box
          sx={{
            marginTop: '1rem',
          }}
        >
          <Typo variant="body2">
            You're onboarding request was successfully submitted. We are
            currently reviewing your request, please wait for 2-3 working days
            for the activation and confirmation of your account. Thank you.
          </Typo>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              marginTop: '1rem',
            }}
          >
            <Typo variant="body2">
              Oops! It seems that your account is not authorized, and hasn't
              confirmed yet.
            </Typo>
            <Typo variant="body2">Should you wish to be onboarded?</Typo>
          </Box>
          <Box
            sx={{
              marginTop: '1.5rem',
            }}
          >
            <FormSelect
              label="Select the role best applies to you"
              name="onboarding-selection"
              selected={onboardType}
              onSelect={handleOnboardSelection}
              options={[
                {
                  value: 'agent',
                  label: 'As an agent',
                },
                {
                  value: 'school',
                  label: 'As a school',
                },
              ]}
            />
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                sx={{ marginTop: '1rem' }}
                loading={requesting}
                variant="outlined"
                onClick={handleOnboardingRequest}
                disabled={onboardType === '' || requesting}
              >
                {' '}
                Send request for onboarding{' '}
              </LoadingButton>
            </Stack>
          </Box>
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={toast.isOpen}
        onClose={handleCloseToast}
        message={toast.message}
        key="top-right"
      ></Snackbar>
    </PageDefaultLayout>
  )
}

export default Onboard
