import React from 'react'
import { useNavigate } from 'react-router-dom'
import type { To } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Paper, Card, Box, Typography as Typo } from '@mui/material'

import { IProductItem } from './types'

const SProductItem = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: theme.customShadows.card,
  borderRadius: theme.shape.borderRadius,
  transition: 'translate .5s ease, box-shadow .5s ease',
  cursor: 'pointer',
  '&:hover': {
    translate: '0 -3%',
    boxShadow: theme.customShadows.cardHover,
  },
}))

const ProductItem = ({
  id,
  name,
  category,
  tag,
  cover,
  coverSize = '50%',
  slug,
}: IProductItem) => {
  const navigate = useNavigate()

  const handleProductItemClick = React.useCallback(() => {
    navigate(slug as To)
  }, [slug])

  return (
    <SProductItem
      elevation={4}
      className="product-item"
      id={id}
      onClick={handleProductItemClick}
    >
      <Box className="product-item_container">
        <Box
          className="product-item_cover"
          sx={(theme) => ({
            display: 'block',
            width: '100%',
            lineHeight: '1',
            overflow: 'hidden',
            position: 'relative',
            paddingTop: `${coverSize}`,
            borderRadius: theme.shape.borderRadius / 6,
            '& figure': {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundSize: 'cover',
              '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              },
            },
          })}
        >
          <figure>
            <img src={cover} alt={`${name} cover`} />
          </figure>
        </Box>
        <Box
          className="product-item_content"
          sx={{
            padding: '1rem',
          }}
        >
          <Typo
            className="product-item_title"
            variant="subtitle2"
            textAlign="center"
            mt={2}
            fontWeight="regular"
          >
            {category}
          </Typo>
          <Typo
            className="product-item_title"
            variant="h4"
            mb={2}
            mt={1}
            textAlign="center"
          >
            {name}
          </Typo>
        </Box>
      </Box>
    </SProductItem>
  )
}

export default ProductItem
