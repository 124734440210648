import React from 'react'
import { Box, Paper, Typography as Typo } from '@mui/material'

import Page from '@learnerlife/components/Page'

const ApplicationDetail = () => {
  return (
    <>
      <Page.Update parentPageName="Applications" pageName="Detail" />
      <Box>
        <Paper></Paper>
      </Box>
    </>
  )
}

export default ApplicationDetail
