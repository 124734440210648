import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'

import {
  Dashboard,
  Agent,
  School,
  Programmes,
  Proposals,
  Applications,
  Welcome,
  Signin,
  Authorize,
  Onboard,
  Profile,
  ContactUs,
  NotFound,
} from './pages'
import ProtectedRoutes from './components/ProtectedRoutes'

import './App.css'

function App() {
  return (
    <>
      <CssBaseline />

      <Routes>
        {/* Public */}
        <Route path="*" element={<Navigate to="/not-found" replace />} />
        <Route path="/authorize" element={<Authorize />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/" element={<Navigate to="/signin" replace />} />

        {/* Protected Routes w roles */}
        <Route
          element={
            <ProtectedRoutes
              allowedUserRoles={[
                'learner:agent',
                'learner:school',
                'learner:student',
              ]}
            />
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
        </Route>

        {/* AGENT */}
        <Route
          element={<ProtectedRoutes allowedUserRoles={['learner:agent']} />}
        >
          <Route
            path="/agent"
            element={<Navigate to="/profile" replace />}
          ></Route>
          <Route path="/programmes" element={<Programmes />} />
          <Route path="/programme">
            <Route path=":programmeSlug" element={<Programmes.Detail />} />
          </Route>
          <Route path="/proposals" element={<Proposals />} />
          <Route path="/proposal">
            <Route path="new" element={<Proposals.Manage />} />
            <Route path="update/:proposalId" element={<Proposals.Manage />} />
            <Route path=":proposalId" element={<Proposals.Detail />} />
          </Route>
          <Route path="/applications" element={<Applications />} />
          <Route path="/application">
            <Route path=":applicationId" element={<Applications.Detail />} />
          </Route>
          <Route path="/school">
            <Route path=":schoolId" element={<School.Detail />} />
          </Route>
        </Route>

        {/* SCHOOL */}
        <Route
          element={<ProtectedRoutes allowedUserRoles={['learner:school']} />}
        >
          <Route
            path="/school"
            element={<Navigate to="/profile" replace />}
          ></Route>
          <Route path="/programmes" element={<Programmes />} />
          <Route path="/programme">
            <Route path="new" element={<Programmes.Manage />} />
            <Route
              path="update/:programmeSlug"
              element={<Programmes.Manage />}
            />
            <Route path=":programmeSlug" element={<Programmes.Detail />} />
          </Route>
          <Route path="/applications" element={<Programmes />} />
          <Route path="/application">
            <Route path=":applicationId" element={<School.Detail />} />
          </Route>
        </Route>

        {/* STUDENT */}
        <Route
          element={<ProtectedRoutes allowedUserRoles={['learner:student']} />}
        >
          <Route
            path="/student"
            element={<Navigate to="/profile" replace />}
          ></Route>
          <Route path="/proposals" element={<Proposals />} />
          <Route path="/proposal">
            <Route path=":proposalId" element={<Proposals.Detail />} />
          </Route>
          <Route path="/applications" element={<Applications />} />
          <Route path="/application">
            <Route path=":applicationId" element={<Applications.Detail />} />
          </Route>
        </Route>

        {/* GENERIC ROLE */}
        <Route element={<ProtectedRoutes />}>
          <Route path="/onboard" element={<Onboard />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
