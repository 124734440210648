import React from 'react'
import {
  Paper,
  Typography as Typo,
  Checkbox,
  Radio,
  RadioGroup,
  FormGroup,
  FormControlLabel,
} from '@mui/material'

import type { IFormChoose, IChooseControl, TChoice } from './types'

const ChooseControl = ({
  checked,
  onChange,
  name,
  multiple,
  selected,
}: IChooseControl) => {
  if (!multiple) {
    return <Radio checked={selected === name} onChange={onChange} name={name} />
  }
  const sel: Record<string, boolean> = { ...(selected as object) }
  return <Checkbox checked={sel[name]} onChange={onChange} name={name} />
}

const FormChoose = ({
  label,
  id,
  choices,
  selected,
  multiple = false,
  onChange,
}: IFormChoose) => {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked, value } = e.target
      if (multiple) {
        onChange(true, id, name, checked)
      } else {
        onChange(false, id, name, name)
      }
    },
    [id, multiple],
  )

  function renderChoices() {
    const renderControl = (idx: number, c: TChoice) => {
      return (
        <FormControlLabel
          key={`formchoose-${id}-${idx}`}
          control={
            <ChooseControl
              // checked={selected[c.value] as boolean}
              selected={selected}
              onChange={handleChange}
              name={c.value}
              multiple={multiple}
            />
          }
          value={c.value}
          label={c.label || c.text}
        />
      )
    }
    if (!multiple) {
      return (
        <RadioGroup name={id} value={selected} onChange={handleChange}>
          {choices.map((c, cIdx) => {
            return renderControl(cIdx, c)
          })}
        </RadioGroup>
      )
    }
    return choices.map((c, cIdx) => {
      return renderControl(cIdx, c)
    })
  }

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '1rem',
        paddingBottom: 0,
      }}
    >
      <Typo variant="h6">{label}</Typo>
      <FormGroup
        sx={{
          marginTop: 1,
        }}
      >
        {renderChoices()}
      </FormGroup>
    </Paper>
  )
}

export default FormChoose
