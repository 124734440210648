import React from 'react'
import { Box } from '@mui/material'
import * as icons from '@mui/icons-material'

import type { IIcon } from './types'
import IconMappings from './mappings'

const Icon = ({ icon, fontSize = 'small', color = undefined, sx }: IIcon) => {
  const IconComp = icon && IconMappings[icon] ? icons[IconMappings[icon]] : null
  if (!IconComp) {
    return null
  }
  return (
    <Box component="span" sx={sx}>
      <IconComp fontSize={fontSize} color={color} />
    </Box>
  )
}

export default Icon
