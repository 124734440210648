import React from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typo from '@mui/material/Typography'

import Programmes from './Programmes'
import Proposals from './proposals/Proposals'
import PropsalDetails from './proposals/ProposalDetail'
import ProposalCreate from './proposals/ProposalCreate'

type TAgentCtx = {
  actions: {
    updatePageName: (name: string) => void
  }
}

const AgentContext = React.createContext({} as TAgentCtx)

const Agent = () => {
  const [pageName, setPageName] = React.useState<string>('')
  const handlePageName = (name: string) => {
    setPageName(name)
  }
  return (
    <AgentContext.Provider
      value={{
        actions: {
          updatePageName: handlePageName,
        },
      }}
    >
      <Box>
        <Box sx={{ marginBottom: '1rem', width: '100%' }}>
          <Typo variant="h4" fontWeight={400}>
            Agent
          </Typo>
          {pageName && <Typo variant="h3"> {pageName} </Typo>}
        </Box>
        <Outlet />
      </Box>
    </AgentContext.Provider>
  )
}

export const useAgent = () => {
  const agentCtx = React.useContext(AgentContext)
  return agentCtx
}

Agent.Programmes = Programmes
Agent.Proposals = Proposals
Agent.PropsalDetails = PropsalDetails
Agent.ProposalCreate = ProposalCreate

export default Agent
