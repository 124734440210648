import React from 'react'
import { styled } from '@mui/material/styles'
import { Stack, Box, Button, Typography as Typo } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'

import Filter from '@learnerlife/components/common/Filter'
import Sort from '@learnerlife/components/common/Sort'

import { IProducts } from './types'
import ProductItem from './ProductItem'

const DEFAULT_NUM_OF_ITEMS = 3

const SProducts = styled(Box)(({ theme }) => ({
  '& .products': {
    display: 'grid',
    gridTemplateColumns: `repeat(${DEFAULT_NUM_OF_ITEMS}, 1fr)`,
    marginBlock: '1rem',
    gap: '1.2rem',
    [theme.breakpoints.up('xs')]: {
      gridTemplateColumns: `repeat(${DEFAULT_NUM_OF_ITEMS - 2}, 1fr)`,
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: `repeat(${DEFAULT_NUM_OF_ITEMS - 1}, 1fr)`,
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: `repeat(${DEFAULT_NUM_OF_ITEMS}, 1fr)`,
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: `repeat(${DEFAULT_NUM_OF_ITEMS}, 1fr)`,
    },
  },
}))

const Products = ({ children }: IProducts) => {
  return (
    <SProducts>
      <div className="app-ctastrip">
        <Stack direction="row" spacing={2}>
          <Button endIcon={<SearchOutlinedIcon />}>Search</Button>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Filter id="products-filter" />
          <Sort id="products-sort" />
        </Stack>
      </div>
      <div className="products">{children}</div>
    </SProducts>
  )
}

Products.Item = ProductItem

export default Products
