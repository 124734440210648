import React from 'react'

import Collapsible from './Collapsible'

const List = () => {
  return null
}

List.Collapsible = Collapsible

export default List
