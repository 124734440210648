import type { AppPage } from '@learnerlife/config'

const findActivePage = (pages: AppPage[], pathname: string): AppPage | null => {
  const map: Record<string, AppPage> = {}

  const traverse = (array: AppPage[]) => {
    array.forEach((item) => {
      map[item.pathname] = item
      traverse(item.pages || [])
    })
  }

  traverse(pages)

  return map[pathname] || null
}

const sleep = (duration: number) => {
  return new Promise((resolve) => setTimeout(resolve, duration))
}

const utils = {
  findActivePage,
  sleep,
}

export default utils
