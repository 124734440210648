import React from 'react'
import { Button, Typography as Typo, Menu, MenuItem } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { ISort } from './types'

const sortOptions = [
  'Newest',
  'Fee: High to Low',
  'Fee: Low to High',
  'Commission: High to Low',
  'Commission: Low to High',
]

const Sort = ({ id }: ISort) => {
  const [sortAnchorEl, setSortAnchorEl] = React.useState<null | HTMLElement>(
    null,
  )

  const [selectedSortItem, setSelectedSortItem] = React.useState(0)

  const open = Boolean(sortAnchorEl)

  const handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortAnchorEl(event.currentTarget)
  }

  const handleSortClose = () => {
    setSortAnchorEl(null)
  }

  const handleSortItemClick =
    (idx: number) => (e: React.MouseEvent<HTMLElement>) => {
      setSelectedSortItem(idx)
      handleSortClose()
    }
  return (
    <>
      <Button
        endIcon={<ExpandMoreIcon fontSize="small" />}
        onClick={handleSortClick}
      >
        Sort By:{' '}
        <Typo variant="subtitle2" ml={1}>
          {sortOptions[selectedSortItem]}
        </Typo>
      </Button>
      <Menu
        id="sort-menu"
        anchorEl={sortAnchorEl}
        open={open}
        onClose={handleSortClose}
        MenuListProps={{
          'aria-labelledby': 'sort-button',
        }}
      >
        {sortOptions.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedSortItem}
            onClick={handleSortItemClick(index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Sort
