import React from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Typography as Typo,
  Paper,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Button,
} from '@mui/material'
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined'

import Page from '@learnerlife/components/Page'
import Tabs from '@learnerlife/components/common/Tabs'

const detailHeadHeight = 300

const ProgrammeDetail: React.FC = () => {
  const { programmeSlug } = useParams()
  console.log('# programmeSlug', programmeSlug)
  return (
    <>
      <Page.Update remove />
      <Box>
        <Paper
          sx={(theme) => ({
            minHeight: detailHeadHeight,
            bgcolor: 'rgba(0,0,0,0.05)',
            borderRadius: theme.shape.borderRadius / 3,
            position: 'relative',
            boxShadow: 'none',
          })}
        >
          <Box
            padding="2rem"
            maxWidth={'70%'}
            sx={{
              paddingRight: '4rem',
            }}
          >
            <Typo variant="h3">NUS Business School: Programme 1</Typo>
            <Typo variant="body1" mt="1em">
              Bridge the gap between Design & Development! Break down detailed
              designs and bring them to life with Material-UI/React.
            </Typo>
          </Box>

          <Box
            sx={(theme) => ({
              position: 'absolute',
              right: 0,
              top: 0,
              marginTop: '2rem',
              marginRight: '2rem',
              width: '30%',
              // minHeight: detailHeadHeight * 2,
              bgcolor: 'rgba(255,255,255,1)',
              borderRadius: theme.shape.borderRadius / 3,
              boxShadow: theme.customShadows.card,
              overflow: 'hidden',
              padding: '1rem',
              paddingBottom: '2rem',
            })}
          >
            <Box
              component="figure"
              sx={(theme) => ({
                borderRadius: theme.shape.borderRadius / 4,
                overflow: 'hidden',
                width: '100%',
                height: `calc(${detailHeadHeight}px - (2rem + 1rem))`,
                '& img': {
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                },
              })}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
                alt="programme cover"
              />
            </Box>

            <Stack direction="column">
              <Box
                sx={{
                  marginBlock: '1rem',
                  paddingInline: '1rem',
                }}
              >
                <Typo variant="body1">From</Typo>
                <Typo
                  variant="h3"
                  sx={{
                    fontSize: '2rem',
                    lineHeight: 1,
                  }}
                >
                  $900
                </Typo>
              </Box>
              <Stack direction="column" spacing={2}>
                <Button variant="contained" size="large" fullWidth>
                  Add to Proposal
                </Button>
                <Button variant="outlined" size="large" fullWidth>
                  Inquire
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Paper>

        <Box sx={{ width: 'calc(70% - 2rem - 1rem)', marginRight: '1rem' }}>
          <Box sx={{ marginBlock: '2rem' }}>
            <Typo variant="h4">What the student will benefit</Typo>

            <List dense>
              <ListItem>
                <ListItemIcon>
                  <DoneAllOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Create beautifully designed production applications from scratch with Material-UI" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DoneAllOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Refine UX/UI design intuition and see concepts in action" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DoneAllOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Migrate from create-react-app to Next JS" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DoneAllOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Export and efficiently integrate animations with react-lottie" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DoneAllOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Build applications that look perfect on every screen with Material-UI's responsive design system" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DoneAllOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Search Engine Optimization in React JS" />
              </ListItem>
            </List>
          </Box>
          <Card
            elevation={2}
            sx={(theme) => ({
              marginBlock: '1rem',
              boxShadow: theme.customShadows.card,
            })}
          >
            <Tabs id="programme-detail-tabs" defaultActiveIndex={1}>
              <Tabs.Panel label="Programme Details">
                <Typo variant="h4">Programme Details</Typo>
              </Tabs.Panel>
              <Tabs.Panel label="School Details">
                <Typo variant="h4">School Details</Typo>
              </Tabs.Panel>
              <Tabs.Panel label="Gallery">
                <Typo variant="h4">Gallery</Typo>
              </Tabs.Panel>
            </Tabs>
          </Card>
        </Box>
      </Box>
    </>
  )
}

export default ProgrammeDetail
