import React from 'react'
import MuiTabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import type { ITabPanel, ITabs, TTabsContextValue } from './types'

import TabPanel from './TabPanel'

const TabsContext = React.createContext({} as TTabsContextValue)

const validTypes = [TabPanel]
const isValidChild = (child: any) => validTypes.some((t) => t === child)

const Tabs = ({ id, defaultActiveIndex, children }: ITabs) => {
  const [tabsConfigValue, setTabsConfigValue] =
    React.useState<TTabsContextValue>({
      tabsId: id,
      value: defaultActiveIndex || 0,
    })

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsConfigValue((prevState: TTabsContextValue) => {
      return {
        ...prevState,
        value: newValue,
      }
    })
  }

  function renderTabs() {
    let idx = 0
    return React.Children.map(children, (child) => {
      const item = child as React.ReactElement<
        React.PropsWithChildren<ITabPanel>
      >
      if (isValidChild(item.type)) {
        idx++
        return <Tab label={item.props.label} id={`${id}-tab-${idx}`} />
      }
      return null
    })
  }

  function renderTabPanels() {
    let idx = 0
    return React.Children.map(children, (child) => {
      const item = child as React.ReactElement<
        React.PropsWithChildren<ITabPanel>
      >
      if (isValidChild(item.type)) {
        idx++
        return React.cloneElement(item, {
          ...item?.props,
          key: `${id}-child-${idx - 1}`,
          index: idx - 1,
        })
      }
      return null
    })
  }

  return (
    <TabsContext.Provider value={tabsConfigValue}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <MuiTabs value={tabsConfigValue.value} onChange={handleChange}>
            {renderTabs()}
          </MuiTabs>
        </Box>
        {renderTabPanels()}
      </Box>
    </TabsContext.Provider>
  )
}

Tabs.Panel = TabPanel

Tabs.propTypes = {
  children: (props: any, propName: string, componentName: string) => {
    const prop = props[propName]
    let error = null
    React.Children.forEach(prop, (child, idx) => {
      if (!isValidChild(child.type)) {
        error = new Error(
          `${componentName}: \`children\` supplied are not valid`,
        )
      }
    })
    return error
  },
}

export const useTabs = () => {
  const tabsCtx = React.useContext(TabsContext)
  return tabsCtx
}

export default Tabs
