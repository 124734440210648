import { createTheme, Theme, ThemeOptions } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: {
      z1: string
      z4: string
      z8: string
      z12: string
      z16: string
      z20: string
      z24: string
      primary: string
      info: string
      secondary: string
      success: string
      warning: string
      error: string
      card: string
      cardHover: string
      dialog: string
      dropdown: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    customShadows?: {
      z1?: string
      z4?: string
      z8?: string
      z12?: string
      z16?: string
      z20?: string
      z24?: string
      primary?: string
      info?: string
      secondary?: string
      success?: string
      warning?: string
      error?: string
      card?: string
      cardHover?: string
      dialog?: string
      dropdown?: string
    }
  }
  // export function createTheme(options?: CustomThemeOptions): CustomTheme
}

const theme = createTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      light: '#5BE584',
      main: '#00AB55',
      dark: '#007B55',
      contrastText: '#fff',
    },
    secondary: {
      light: '#84A9FF',
      main: '#3366FF',
      dark: '#1939B7',
      contrastText: '#fff',
    },
    info: {
      light: '#61F3F3',
      main: '#00B8D9',
      dark: '#006C9C',
      contrastText: '#fff',
    },
    success: {
      light: '#86E8AB',
      main: '#36B37E',
      dark: '#1B806A',
      contrastText: '#fff',
    },
    warning: {
      light: '#FFD666',
      main: '#FFAB00',
      dark: '#B76E00',
      contrastText: '#212B36',
    },
    error: {
      light: '#FFAC82',
      main: '#FF5630',
      dark: '#B71D18',
      contrastText: '#fff',
    },
    grey: {
      '100': '#F9FAFB',
      '200': '#F4F6F8',
      '300': '#DFE3E8',
      '400': '#C4CDD5',
      '500': '#919EAB',
      '600': '#637381',
      '700': '#454F5B',
      '800': '#212B36',
      '900': '#161C24',
    },
    divider: 'rgba(145, 158, 171, 0.24)',
    action: {
      hover: 'rgba(145, 158, 171, 0.08)',
      selected: 'rgba(145, 158, 171, 0.16)',
      disabled: 'rgba(145, 158, 171, 0.8)',
      disabledBackground: 'rgba(145, 158, 171, 0.24)',
      focus: 'rgba(145, 158, 171, 0.24)',
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
      active: '#637381',
    },
    mode: 'light',
    text: {
      primary: '#212B36',
      secondary: '#637381',
      disabled: '#919EAB',
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Roboto, Public Sans, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 800,
      lineHeight: 1.25,
      fontSize: '2.5rem',
      '@media (min-width:600px)': '{fontSize: "3.25rem"}',
      '@media (min-width:900px)': '{fontSize: "3.625rem"}',
      '@media (min-width:1200px)': '{fontSize: "4rem"}',
    },
    h2: {
      fontWeight: 800,
      lineHeight: 1.3333333333333333,
      fontSize: '2rem',
      '@media (min-width:600px)': '{fontSize: "2.5rem"}',
      '@media (min-width:900px)': '{fontSize: "2.75rem"}',
      '@media (min-width:1200px)': '{fontSize: "3rem"}',
    },
    h3: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: '1.5rem',
      '@media (min-width:600px)': '{fontSize: "1.625rem"}',
      '@media (min-width:900px)': '{fontSize: "1.875rem"}',
      '@media (min-width:1200px)': '{fontSize: "2rem"}',
    },
    h4: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: '1.25rem',
      '@media (min-width:600px)': '{fontSize: "1.25rem"}',
      '@media (min-width:900px)': '{fontSize: "1.5rem"}',
      '@media (min-width:1200px)': '{fontSize: "1.5rem"}',
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: '1.125rem',
      '@media (min-width:600px)': '{fontSize: "1.1875rem"}',
      '@media (min-width:900px)': '{fontSize: "1.25rem"}',
      '@media (min-width:1200px)': '{fontSize: "1.25rem"}',
    },
    h6: {
      fontWeight: 700,
      lineHeight: 1.5555555555555556,
      fontSize: '1.0625rem',
      '@media (min-width:600px)': '{fontSize: "1.125rem"}',
      '@media (min-width:900px)': '{fontSize: "1.125rem"}',
      '@media (min-width:1200px)': '{fontSize: "1.125rem"}',
    },
    subtitle1: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: '1rem',
    },
    subtitle2: {
      fontWeight: 600,
      lineHeight: 1.5714285714285714,
      fontSize: '0.875rem',
    },
    body1: {
      lineHeight: 1.5,
      fontSize: '1rem',
    },
    body2: {
      lineHeight: 1.5714285714285714,
      fontSize: '0.875rem',
    },
    caption: {
      lineHeight: 1.5,
      fontSize: '0.75rem',
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: '0.75rem',
      textTransform: 'uppercase',
    },
    button: {
      fontWeight: 700,
      lineHeight: 1.7142857142857142,
      fontSize: '0.875rem',
      textTransform: 'capitalize',
    },
  },
  shape: {
    borderRadius: 8,
  },
  direction: 'ltr',
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(145, 158, 171, 0.2),0px 1px 1px 0px rgba(145, 158, 171, 0.14),0px 1px 3px 0px rgba(145, 158, 171, 0.12)',
    '0px 3px 1px -2px rgba(145, 158, 171, 0.2),0px 2px 2px 0px rgba(145, 158, 171, 0.14),0px 1px 5px 0px rgba(145, 158, 171, 0.12)',
    '0px 3px 3px -2px rgba(145, 158, 171, 0.2),0px 3px 4px 0px rgba(145, 158, 171, 0.14),0px 1px 8px 0px rgba(145, 158, 171, 0.12)',
    '0px 2px 4px -1px rgba(145, 158, 171, 0.2),0px 4px 5px 0px rgba(145, 158, 171, 0.14),0px 1px 10px 0px rgba(145, 158, 171, 0.12)',
    '0px 3px 5px -1px rgba(145, 158, 171, 0.2),0px 5px 8px 0px rgba(145, 158, 171, 0.14),0px 1px 14px 0px rgba(145, 158, 171, 0.12)',
    '0px 3px 5px -1px rgba(145, 158, 171, 0.2),0px 6px 10px 0px rgba(145, 158, 171, 0.14),0px 1px 18px 0px rgba(145, 158, 171, 0.12)',
    '0px 4px 5px -2px rgba(145, 158, 171, 0.2),0px 7px 10px 1px rgba(145, 158, 171, 0.14),0px 2px 16px 1px rgba(145, 158, 171, 0.12)',
    '0px 5px 5px -3px rgba(145, 158, 171, 0.2),0px 8px 10px 1px rgba(145, 158, 171, 0.14),0px 3px 14px 2px rgba(145, 158, 171, 0.12)',
    '0px 5px 6px -3px rgba(145, 158, 171, 0.2),0px 9px 12px 1px rgba(145, 158, 171, 0.14),0px 3px 16px 2px rgba(145, 158, 171, 0.12)',
    '0px 6px 6px -3px rgba(145, 158, 171, 0.2),0px 10px 14px 1px rgba(145, 158, 171, 0.14),0px 4px 18px 3px rgba(145, 158, 171, 0.12)',
    '0px 6px 7px -4px rgba(145, 158, 171, 0.2),0px 11px 15px 1px rgba(145, 158, 171, 0.14),0px 4px 20px 3px rgba(145, 158, 171, 0.12)',
    '0px 7px 8px -4px rgba(145, 158, 171, 0.2),0px 12px 17px 2px rgba(145, 158, 171, 0.14),0px 5px 22px 4px rgba(145, 158, 171, 0.12)',
    '0px 7px 8px -4px rgba(145, 158, 171, 0.2),0px 13px 19px 2px rgba(145, 158, 171, 0.14),0px 5px 24px 4px rgba(145, 158, 171, 0.12)',
    '0px 7px 9px -4px rgba(145, 158, 171, 0.2),0px 14px 21px 2px rgba(145, 158, 171, 0.14),0px 5px 26px 4px rgba(145, 158, 171, 0.12)',
    '0px 8px 9px -5px rgba(145, 158, 171, 0.2),0px 15px 22px 2px rgba(145, 158, 171, 0.14),0px 6px 28px 5px rgba(145, 158, 171, 0.12)',
    '0px 8px 10px -5px rgba(145, 158, 171, 0.2),0px 16px 24px 2px rgba(145, 158, 171, 0.14),0px 6px 30px 5px rgba(145, 158, 171, 0.12)',
    '0px 8px 11px -5px rgba(145, 158, 171, 0.2),0px 17px 26px 2px rgba(145, 158, 171, 0.14),0px 6px 32px 5px rgba(145, 158, 171, 0.12)',
    '0px 9px 11px -5px rgba(145, 158, 171, 0.2),0px 18px 28px 2px rgba(145, 158, 171, 0.14),0px 7px 34px 6px rgba(145, 158, 171, 0.12)',
    '0px 9px 12px -6px rgba(145, 158, 171, 0.2),0px 19px 29px 2px rgba(145, 158, 171, 0.14),0px 7px 36px 6px rgba(145, 158, 171, 0.12)',
    '0px 10px 13px -6px rgba(145, 158, 171, 0.2),0px 20px 31px 3px rgba(145, 158, 171, 0.14),0px 8px 38px 7px rgba(145, 158, 171, 0.12)',
    '0px 10px 13px -6px rgba(145, 158, 171, 0.2),0px 21px 33px 3px rgba(145, 158, 171, 0.14),0px 8px 40px 7px rgba(145, 158, 171, 0.12)',
    '0px 10px 14px -6px rgba(145, 158, 171, 0.2),0px 22px 35px 3px rgba(145, 158, 171, 0.14),0px 8px 42px 7px rgba(145, 158, 171, 0.12)',
    '0px 11px 14px -7px rgba(145, 158, 171, 0.2),0px 23px 36px 3px rgba(145, 158, 171, 0.14),0px 9px 44px 8px rgba(145, 158, 171, 0.12)',
    '0px 11px 15px -7px rgba(145, 158, 171, 0.2),0px 24px 38px 3px rgba(145, 158, 171, 0.14),0px 9px 46px 8px rgba(145, 158, 171, 0.12)',
  ],
  customShadows: {
    z1: '0 1px 2px 0 rgba(145, 158, 171, 0.16)',
    z4: '0 4px 8px 0 rgba(145, 158, 171, 0.16)',
    z8: '0 8px 16px 0 rgba(145, 158, 171, 0.16)',
    z12: '0 12px 24px -4px rgba(145, 158, 171, 0.16)',
    z16: '0 16px 32px -4px rgba(145, 158, 171, 0.16)',
    z20: '0 20px 40px -4px rgba(145, 158, 171, 0.16)',
    z24: '0 24px 48px 0 rgba(145, 158, 171, 0.16)',
    primary: '0 8px 16px 0 rgba(0, 171, 85, 0.24)',
    info: '0 8px 16px 0 rgba(0, 184, 217, 0.24)',
    secondary: '0 8px 16px 0 rgba(51, 102, 255, 0.24)',
    success: '0 8px 16px 0 rgba(54, 179, 126, 0.24)',
    warning: '0 8px 16px 0 rgba(255, 171, 0, 0.24)',
    error: '0 8px 16px 0 rgba(255, 86, 48, 0.24)',
    card: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)',
    cardHover:
      '0 16px 12px 1px rgba(145, 158, 171, 0.1), 0 24px 24px -4px rgba(145, 158, 171, 0.2)',
    dialog: '-40px 40px 80px -8px rgba(145, 158, 171, 0.24)',
    dropdown:
      '0 0 2px 0 rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24)',
  },
})

export default theme
