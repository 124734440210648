import React from 'react'
import {
  Box,
  Button,
  Drawer,
  Stack,
  Typography as Typo,
  Divider,
  Paper,
} from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import TuneIcon from '@mui/icons-material/Tune'
import CloseIcon from '@mui/icons-material/Close'

import FormChoose from '../Form/FormChoose'

import type {
  IFilter,
  TFilterAnchor,
  TFilterGroup,
  TFilterValues,
} from './types'

const Filter = ({ id }: IFilter) => {
  const [toggle, setToggle] = React.useState<boolean>(false)
  const [anchor, _] = React.useState<TFilterAnchor>('right')
  const [filters, setFilters] = React.useState<
    TFilterGroup<string, TFilterValues | string>
  >({
    regions: {
      asia: false,
      africa: false,
      europe: false,
    },
    teaching_language: {
      german: false,
      english: true,
      french: false,
    },
    types_of_institution: 'international',
  })

  const handleFilterDrawerToggle = (
    e: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      e.type === 'keydown' &&
      ((e as React.KeyboardEvent).key === 'Tab' ||
        (e as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setToggle(!toggle)
  }

  const handleFilterChange = (
    multiple: boolean,
    group: string,
    name: string,
    value: boolean | string,
  ) => {
    if (multiple) {
      setFilters((prevState) => {
        const f: TFilterValues = prevState[group] as TFilterValues
        f[name] = value as boolean
        return {
          ...prevState,
          [group]: {
            ...f,
          },
        }
      })
    } else {
      setFilters((prevState) => {
        const newState = { ...prevState }
        newState[group] = value as string
        return {
          ...newState,
        }
      })
    }
  }

  return (
    <>
      <Button
        id={`filter-button-${id}`}
        endIcon={<TuneIcon fontSize="small" />}
        onClick={handleFilterDrawerToggle}
      >
        Filters
      </Button>
      <Drawer
        id={`filter-drawer-${id}`}
        anchor={anchor}
        open={toggle}
        onClose={handleFilterDrawerToggle}
        sx={{
          '& > .MuiPaper-root': {
            width: '300px',
          },
          '& .MuiBackdrop-root': {
            bgcolor: 'rgba(255,255,255, 0.5)',
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding="1rem"
          sx={{
            minHeight: '92px',
          }}
        >
          <Typo variant="h6">Filters</Typo>
          <Button>
            <CloseIcon fontSize="small" color="action" />
          </Button>
        </Stack>
        <Divider />
        <Stack
          direction="column"
          sx={{
            flexGrow: 1,
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Paper
            elevation={0}
            sx={{
              position: 'relative',
              maxHeight: '100%',
              alignContent: 'flex-start',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            <Paper
              elevation={0}
              sx={{
                height: 'auto',
                overflow: 'hidden scroll',
                maxHeight: '100%',
                position: 'relative',
                width: '100%',
              }}
            >
              <FormChoose
                multiple
                id="regions"
                label="Regions"
                choices={[
                  {
                    text: 'Asia',
                    value: 'asia',
                  },
                  {
                    text: 'Africa',
                    value: 'africa',
                  },
                  {
                    text: 'Europe',
                    value: 'europe',
                  },
                ]}
                selected={filters['regions'] as TFilterValues}
                onChange={handleFilterChange}
              />

              <FormChoose
                multiple
                id="teaching_language"
                label="Teaching Language"
                choices={[
                  {
                    text: 'German',
                    value: 'german',
                  },
                  {
                    text: 'English',
                    value: 'english',
                  },
                  {
                    text: 'French',
                    value: 'french',
                  },
                ]}
                selected={filters['teaching_language'] as TFilterValues}
                onChange={handleFilterChange}
              />

              <FormChoose
                id="types_of_institution"
                label="Types Of Institution"
                choices={[
                  {
                    text: 'International',
                    value: 'international',
                  },
                  {
                    text: 'Government',
                    value: 'government',
                  },
                  {
                    text: 'Private',
                    value: 'private',
                  },
                ]}
                selected={filters['types_of_institution'] as string}
                onChange={handleFilterChange}
              />
            </Paper>
          </Paper>
        </Stack>
        <Box padding="1rem">
          <Button> Clear Filters </Button>
        </Box>
      </Drawer>
    </>
  )
}

export default Filter
