/* eslint-disable curly */
import React from 'react'
import { Card, Box, Typography as Typo, LinearProgress } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

import { PageDefaultLayout } from '@learnerlife/components/common/Page'
import { useFirebaseApp } from '@learnerlife/contexts/FirebaseApp'

const Authorize = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useFirebaseApp()

  React.useEffect(() => {
    if (user) {
      const getPath = (): string => {
        if (location.state?.noRolesMatched) return '/404'
        if (location.state?.noRoles) return '/onboard'
        return location.state?.from?.pathname || '/profile'
      }
      // Simulate authorization, getting roles and access from API
      setTimeout(() => {
        const pathToAccess = getPath()
        navigate(pathToAccess, { replace: true, state: location.state })
      }, 1000)
    }
  }, [user])

  return (
    <PageDefaultLayout>
      <Typo variant="h3">Please wait..</Typo>
      <LinearProgress color="success" />
    </PageDefaultLayout>
  )
}

export default Authorize
