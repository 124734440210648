import React from 'react'
import { Card, Box, Typography as Typo, Button, Stack } from '@mui/material'

import type { IPageDefaultLayout } from './types'

const PageDefaultLayout = ({ children }: IPageDefaultLayout) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexBasis: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.05)',
      }}
    >
      <Card
        sx={(theme) => ({
          width: 'calc(100% - 2rem)',
          marginInline: '1rem',
          maxWidth: '400px',
          padding: theme.spacing(4),
          borderRadius: theme.shape.borderRadius / 6,
          boxShadow: theme.customShadows.card,
          background: 'white',
        })}
      >
        {children}
      </Card>
    </Box>
  )
}

export default PageDefaultLayout
