import { Box } from '@mui/material'
import React from 'react'
import { ISpanIconProps } from './types'
const SpanIcon = ({
  value,
  textColor = 'white',
  bgColor = 'black',
}: ISpanIconProps) => {
  return (
    <Box
      component="span"
      sx={{
        height: '24px',
        minHeight: '22px',
        lineHeight: 0,
        borderRadius: '6px',
        cursor: 'default',
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'capitalize',
        padding: '0px 8px',
        color: textColor,
        fontSize: '0.75rem',
        backgroundColor: bgColor,
        marginRight: '10px',
      }}
    >
      {value}
    </Box>
  )
}

export default SpanIcon
