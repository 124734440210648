import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: 'learner-life-dev-369205.firebaseapp.com',
  projectId: 'learner-life-dev-369205',
  storageBucket: 'learner-life-dev-369205.appspot.com',
  messagingSenderId: '220342313559',
}

const init = () => initializeApp(firebaseConfig)

export default init
