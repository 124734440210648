/* eslint-disable curly */

const DEFAULT_KEY = process.env.STORAGE_KEY || 'awds:learner:store'

const Retrieve = (parse = false, key = DEFAULT_KEY) => {
  const data = window.sessionStorage.getItem(key)
  if (parse) {
    if (data) {
      return JSON.parse(data)
    }
    return {}
  }
  return data
}

const Save = (value: any, key = DEFAULT_KEY) => {
  const val = JSON.stringify(value)
  window.sessionStorage.setItem(key, val)
}

const Delete = (key = DEFAULT_KEY) => {
  const data = Retrieve(false, key)
  if (!data) return false

  window.sessionStorage.removeItem(key)
  return true
}

const Clear = () => {
  window.sessionStorage.clear()
}

const storage = {
  Retrieve,
  Save,
  Delete,
  Clear,
}

export default storage
