import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  getAuth,
  User,
} from 'firebase/auth'

import {
  FirebaseService,
  APIServices,
  StorageService,
} from '@learnerlife/services'

import type {
  IFirebaseApp,
  TFirebaseContextValue,
  TUserDetails,
  TUser,
} from './types'

const app = FirebaseService.app()
const auth = FirebaseService.auth(app)

const FirebaseCtx = React.createContext({} as TFirebaseContextValue)

const FirebaseApp = ({ children }: IFirebaseApp) => {
  const [user, setUser] = React.useState<TUser | null>(null)
  const [currentUser, setCurrentUser] = React.useState<User | null>(() => {
    if (auth && auth.currentUser) {
      return auth.currentUser
    }
    return null
  })

  const navigate = useNavigate()
  const location = useLocation()

  const handleSignIn = () => {
    const googPvdr = new GoogleAuthProvider()
    signInWithPopup(auth, googPvdr)
  }

  const handleSignOut = () => {
    signOut(auth).then(() => {
      StorageService.Delete()
      navigate('/signin', { replace: true })
    })
  }

  const handleGetUser = async () => {
    const tkn = await user?.currentUser?.getIdTokenResult()
    return tkn
  }

  React.useEffect(() => {
    const unsub = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        ;(async function token() {
          const tkn = await authUser?.getIdTokenResult()
          // Token will be avail in the store and should be attached to API calls
          // const roles = await APIServices.user.getAuthorization<string[]>(
          //   tkn.token,
          // )
          setUser((prevState) => {
            if (!authUser) {
              return null
            }
            return {
              ...prevState,
              currentUser: authUser,
              // roles: roles ?? undefined,
              roles: ['learner:agent'], // temporary only, roles[] will be coming from the API
            }
          })
        })()
        StorageService.Save({ sess: true })
      }
    })
    return () => {
      unsub()
    }
  }, [])

  React.useEffect(() => {
    console.log('user ', user)
    // Provide a map of public routes and whitelist them to be accessed without authentication
    if (user?.currentUser) {
      if (location.state?.from?.pathname !== '/authorize') {
        navigate('/authorize', { state: location.state })
      }
    }
  }, [user])

  return (
    <FirebaseCtx.Provider
      value={{
        user,
        currentUser: currentUser,
        actions: {
          signin: handleSignIn,
          signout: handleSignOut,
          getUser: handleGetUser,
        },
      }}
    >
      {children}
    </FirebaseCtx.Provider>
  )
}

export const useFirebaseApp = () => {
  const ctxValue = React.useContext(FirebaseCtx)
  return ctxValue
}

export default FirebaseApp
