import React from 'react'
import { Box, Button, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

import type { IPreviewProps } from './types'

const Preview = ({ files }: IPreviewProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBlock: '1rem',
        '& .preview-items': {
          display: 'block',
        },
        '& .preview-item': {
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80px',
          height: '80px',
          borderRadius: '10px',
          overflow: 'hidden',
          position: 'relative',
          border: '1px solid rgba(145, 158, 171, 0.24)',
          '& figure': {
            lineHeight: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            '& img': {
              width: '100%',
              height: '100%',
              flexShrink: 0,
              objectFit: 'cover',
            },
          },
        },
        '& .preview-ctas': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '0.5rem',
        },
      }}
    >
      <div className="preview-items">
        <div className="preview-item">
          <figure aria-label="test-item1">
            <img src="/images/fileupload.svg" alt="test-item1" />
          </figure>
          <IconButton
            sx={{
              position: 'absolute',
              top: '0.20rem',
              right: '0.20rem',
              padding: '0.1em',
            }}
          >
            <Close fontSize="small" color="action" />
          </IconButton>
        </div>
      </div>
      <div className="preview-ctas">
        <Button variant="outlined" size="small">
          Remove all
        </Button>
        <Button variant="contained" size="small">
          Upload files
        </Button>
      </div>
    </Box>
  )
}

export default Preview
