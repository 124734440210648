import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import { ISelectionProps } from './types'
const Selection = ({
  id,
  labelId,
  label,
  items,
  onChange,
  value,
  style = {},
  size = 'medium',
}: ISelectionProps) => {
  function renderSelect() {
    return (
      <>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          id={id}
          value={value}
          label={label}
          onChange={onChange}
        >
          {items.map((e) => (
            <MenuItem value={e}>{e}</MenuItem>
          ))}
        </Select>
      </>
    )
  }

  return (
    <FormControl fullWidth sx={style} size={size}>
      {renderSelect()}
    </FormControl>
  )
}

export default Selection
