import React from 'react'
import { FormControl, Typography as Typo, Box } from '@mui/material'
import { useDropzone } from 'react-dropzone'

import Preview from './Preview'

import type { IFormDropzone } from './types'

const FormDropzone = ({ files, label }: IFormDropzone) => {
  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    // Do something with the files
    console.log(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  return (
    <FormControl
      fullWidth
      variant="outlined"
      sx={{ alignItems: 'flex-start', paddingTop: '1rem' }}
    >
      {label && <Typo variant="subtitle2">{label}</Typo>}
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          marginTop: '0.8rem',
          '& > div:first-child': {
            outline: 'none',
            cursor: 'pointer',
            overflow: 'hidden',
            position: 'relative',
            padding: '40px',
            borderRadius: '.5rem',
            border: '1px dashed rgba(145, 158, 171, 0.32)',
          },
          '& img': {
            aspectRatio: 'auto',
            height: '200px',
          },
        }}
      >
        <div role="presentation" tabIndex={0} {...getRootProps()}>
          <input
            type="file"
            tabIndex={-1}
            accept="image/*"
            {...getInputProps()}
          />
          <div>
            <img src="/images/fileupload.svg" alt="file upload hero" />
          </div>
          <div>
            <Typo variant="h5">Drop or Select file</Typo>
            <Typo variant="body2">
              Drop files here or click browse thorough your machine
            </Typo>
          </div>
        </div>

        <Preview />
      </Box>
    </FormControl>
  )
}

export default FormDropzone
