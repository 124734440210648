import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import type { ITabPanel } from './types'

import { useTabs } from './Tabs'

const TabPanel = (props: ITabPanel) => {
  const { children, index, ...other } = props
  const { tabsId, value } = useTabs()
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${tabsId}-tabpanel-${index}`}
      aria-labelledby={`${tabsId}-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export default TabPanel
