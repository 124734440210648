import React from 'react'
import { useLocation } from 'react-router-dom'
import classnames from 'classnames'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import type { ICollapsible } from './types'

import Icon from '../Icon'
import Link from '../Link'

const Collapsible = ({ icon, children, items, sx }: ICollapsible) => {
  const location = useLocation()
  const [open, setOpen] = React.useState<boolean>(false)

  const isActive = React.useMemo(() => {
    return items.some((o) => o.to === location.pathname) && open
  }, [open, location, items])

  const _Collapsible = classnames('app-listcollapsible-item', {
    active: isActive,
  })

  const handleClick = () => {
    setOpen(!open)
  }

  function renderItems() {
    return items.map((item, idx) => {
      return (
        <ListItem
          key={`collapsible-${idx}`}
          sx={{
            padding: 0,
            paddingBlock: 0.2,
            marginBlock: 0.5,
          }}
        >
          <ListItemButton
            key={`collapsible-item-${idx}`}
            component={Link}
            to={item.to}
            sx={(theme) => ({
              padding: 0,
              paddingBlock: 0.5,
              '&.active': {
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary.dark
                    : theme.palette.primary.main,
                '& .MuiListItemIcon-root': {
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.primary.dark
                      : theme.palette.primary.main,
                  '& .app-bullet': {
                    scale: '2',
                  },
                },
                '& .MuiTypography-root': {
                  fontWeight: 'bold',
                },
              },
            })}
          >
            <ListItemIcon
              sx={{
                minWidth: '40px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {item.icon ? (
                <Icon icon={item.icon} />
              ) : (
                <i className="app-bullet" />
              )}
            </ListItemIcon>
            <ListItemText
              sx={{ fontSize: 0.8, margin: 0 }}
              primary={item.content}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItemButton>
        </ListItem>
      )
    })
  }

  return (
    <>
      <ListItemButton className={_Collapsible} onClick={handleClick} sx={sx}>
        <ListItemIcon sx={{ minWidth: 0 }}>
          <Icon
            icon={icon}
            sx={{
              '& svg': { fontSize: (theme) => theme.typography.pxToRem(16.5) },
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              marginRight: 1.5,
              py: '2px',
            }}
          />
        </ListItemIcon>
        <ListItemText primary={children} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        className="app-collapsible"
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{ width: '100%' }}
      >
        <List disablePadding>{renderItems()}</List>
      </Collapse>
    </>
  )
}

export default Collapsible
