/* eslint-disable curly */
import React from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'

import { Container, Box, Typography as Typo } from '@mui/material'

import Header from '../Header'
import Sidebar from '../Sidebar'

import PageUpdate from './PageUpdate'

type TPageCtx = {
  actions: {
    updatePageContent: (value: TPageContent) => void
  }
}

type TPageContent = {
  parentPageName?: string | null
  pageName?: string | null
} | null

const PageContext = React.createContext({} as TPageCtx)

const Page = () => {
  const [page, setPage] = React.useState<TPageContent>({
    parentPageName: '',
    pageName: '',
  })

  const handlePageContent = (value: TPageContent) => {
    setPage((prevState) => {
      if (!value) return null as TPageContent
      return {
        ...prevState,
        ...value,
      } as TPageContent
    })
  }

  return (
    <PageContext.Provider
      value={{
        actions: {
          updatePageContent: handlePageContent,
        },
      }}
    >
      <main>
        <Header />
        <Container sx={{ width: 1 }}>
          <Box>
            {page && (
              <Box sx={{ marginBottom: '1rem', width: '100%' }}>
                {page?.parentPageName && (
                  <Typo variant="h4" fontWeight={400}>
                    {page?.parentPageName}
                  </Typo>
                )}

                {page?.pageName && <Typo variant="h3"> {page?.pageName} </Typo>}
              </Box>
            )}
            <Outlet />
          </Box>
        </Container>
      </main>
    </PageContext.Provider>
  )
}

export const usePage = () => {
  const pageCtx = React.useContext(PageContext)
  return pageCtx
}

Page.Update = PageUpdate

export default Page
