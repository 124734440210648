import React from 'react'
import { Box, Paper, Typography as Typo } from '@mui/material'

import Page from '@learnerlife/components/Page'

import ApplicationDetail from './ApplicationDetail'

const Applications = () => {
  return (
    <>
      <Page.Update
        parentPageName="Applications"
        pageName="List of applications"
      />
      <Box>
        <Paper></Paper>
      </Box>
    </>
  )
}

Applications.Detail = ApplicationDetail

export default Applications
