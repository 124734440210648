import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ProgrammesConstants from '../constants/programmes.constants'

import Utils from '@learnerlife/tools/utils'

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000'

const getProgrammes = () => {
  return (dispatch: any) => {
    console.log('# getting programmes...')

    const fetchProgrammes = async () => {
      try {
        // const response = await fetch(`${API_BASE_URL}/api/v1/programmes`)
        // const result = await response.json()
        let result = null
        result = {
          programmes: [
            {
              name: 'Programme 1',
              id: 'programme-1',
            },
          ],
        }
        await Utils.sleep(1000)
        return result
      } catch (error) {
        throw error
      }
    }

    dispatch({
      type: ProgrammesConstants.PROGRAMMES_GET_LIST,
    })
    fetchProgrammes()
      .then((result) => {
        console.log('# result', result)
        dispatch({
          type: ProgrammesConstants.PROGRAMMES_GET_LIST_SUCCESS,
          payload: result,
        })
      })
      .catch((err) => {
        dispatch({
          type: ProgrammesConstants.PROGRAMMES_GET_LIST_FAIL,
          payload: err,
        })
      })
  }
}

const actions = {
  getProgrammes,
}

export default actions
