/* eslint-disable curly */
export type TRoles =
  | 'learner:agent'
  | 'learner:school'
  | 'learner:student'
  | 'learner:user'

function renderRole(roles: TRoles[]) {
  if (!roles) return null
  if (roles.includes('learner:agent')) return 'Agent'
  if (roles.includes('learner:school')) return 'school'
  return null
}
const helpers = {
  renderRole,
}

export default helpers
