import React from 'react'
import { Box } from '@mui/material'

import Product from '@learnerlife/components/Product'

import ProgrammeManage from './ProgrammeManage'
import ProgrammeDetail from './ProgrammeDetail'
import { Outlet } from 'react-router-dom'

import Page from '@learnerlife/components/Page'

const Programmes = () => {
  return (
    <>
      <Page.Update
        parentPageName="Programmes"
        pageName="Programmes Available"
      />
      <Box>
        <Product>
          <Product.Item
            id="school-programmes-1"
            name="NUS Business School: Programme 1"
            category="English Primary National Curriculum"
            cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
            slug="/programme/nus-business-school-programme-1"
          />
          <Product.Item
            id="school-programmes-2"
            name="SOTA: Programme 2"
            category="Degree Honors"
            cover="https://www.sota.edu.sg/images/default-source/default-album/branding-mobile.jpg"
            slug="/programme/sota-programme-1"
          />
          <Product.Item
            id="school-programmes-3"
            name="NTU: Programme 3"
            category="Degree Honors"
            cover="https://www.ntu.edu.sg/images/default-source/ogem/dsc_754109e1aa2b-1dd5-4d48-935b-dfec268c99b8.jpg?Status=Master&sfvrsn=683bc53b_3"
          />
          <Product.Item
            id="school-programmes-3"
            name="SOTA: Programme 3"
            category="English Primary National Curriculum"
            cover="https://www.sota.edu.sg/images/default-source/default-album/branding-mobile.jpg"
          />
          <Product.Item
            id="school-programmes-3"
            name="NUS Business School: Programme 3"
            category="English Primary National Curriculum"
            cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
          />

          <Product.Item
            id="school-programmes-3"
            name="NUS Business School: Programme 3"
            category="English Primary National Curriculum"
            cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
          />
          <Product.Item
            id="school-programmes-1"
            name="NUS Business School: Programme 1"
            category="English Primary National Curriculum"
            cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
          />
          <Product.Item
            id="school-programmes-2"
            name="SOTA: Programme 2"
            category="Degree Honors"
            cover="https://www.sota.edu.sg/images/default-source/default-album/branding-mobile.jpg"
          />
          <Product.Item
            id="school-programmes-3"
            name="NTU: Programme 3"
            category="Degree Honors"
            cover="https://www.ntu.edu.sg/images/default-source/ogem/dsc_754109e1aa2b-1dd5-4d48-935b-dfec268c99b8.jpg?Status=Master&sfvrsn=683bc53b_3"
          />
          <Product.Item
            id="school-programmes-3"
            name="SOTA: Programme 3"
            category="English Primary National Curriculum"
            cover="https://www.sota.edu.sg/images/default-source/default-album/branding-mobile.jpg"
          />
          <Product.Item
            id="school-programmes-3"
            name="NUS Business School: Programme 3"
            category="English Primary National Curriculum"
            cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
          />

          <Product.Item
            id="school-programmes-3"
            name="NUS Business School: Programme 3"
            category="English Primary National Curriculum"
            cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
          />
        </Product>
      </Box>
      <Outlet />
    </>
  )
}

Programmes.Detail = ProgrammeDetail
Programmes.Manage = ProgrammeManage

export default Programmes
