/* eslint-disable curly */
import React from 'react'
import { Link } from 'react-router-dom'
import { Stack, Avatar } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Typo from '@mui/material/Typography'

import Nav from './Nav'

import { useFirebaseApp } from '@learnerlife/contexts/FirebaseApp'
import Helpers from '@learnerlife/tools/helpers'
import type { TRoles } from '@learnerlife/tools/helpers'

const Sidebar = () => {
  const { user, actions } = useFirebaseApp()

  return (
    <aside className="app-sidebar">
      <Drawer
        sx={{
          width: '100%',
          '& .MuiDrawer-paper': {
            width: '240px',
            background: 'none',
            boxSizing: 'border-box',
          },
          '.app-logo': {
            lineHeight: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& img': {
              height: '50%',
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open
      >
        <div className="app-sidebar_head">
          <figure className="app-logo">
            <img src="/images/ll_logo.png" alt="learner life logo" />
          </figure>
          <Box
            sx={{
              bgcolor: 'rgba(0,0,0,0.05)',
              borderRadius: (theme) => theme.shape.borderRadius / 4,
              padding: (theme) => theme.spacing(2),
            }}
          >
            {user?.currentUser && (
              <Stack direction="row">
                <Avatar
                  alt={user?.currentUser?.displayName || ''}
                  src={user?.currentUser?.photoURL || ''}
                />
                <Box ml={2}>
                  <Typo
                    variant="h5"
                    sx={{ lineHeight: '1.2', marginBottom: '0.5rem' }}
                  >
                    {' '}
                    {user?.currentUser?.displayName}{' '}
                  </Typo>
                  <Typo variant="subtitle2" fontWeight={400} lineHeight={1}>
                    {Helpers.renderRole(user.roles as TRoles[])}
                  </Typo>
                </Box>
              </Stack>
            )}
          </Box>
        </div>

        <Nav />
      </Drawer>
    </aside>
  )
}

export default Sidebar
