import React from 'react'
import {
  Box,
  Paper,
  Divider,
  Typography as Typo,
  Button,
  TextField,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  OutlinedInput,
} from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'

import Selection from '@learnerlife/components/common/Selection/Select'
import TimePicker from '@learnerlife/components/common/TimePicker'

import { useAgent } from '..'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const ProposalCreate = () => {
  const agent = useAgent()

  React.useEffect(() => {
    agent.actions.updatePageName('Proposal Create')
  }, [])

  const flexStyle = {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: '0px',
    padding: '0px',
    margin: '0px 0px 0px 16px',
    border: '0px',
    verticalAlign: 'top',
    width: '100%',
  }

  const computationStyle = {
    margin: '16px 0px 0px 0px',
    lineHeight: 1.5,
    fontSize: '1rem',
    fontWeight: 400,
  }

  const [status, setStatus] = React.useState('')
  const [dateCreate, setDateCreate] = React.useState<Dayjs | null>(null)
  const [dateDue, setDateDue] = React.useState<Dayjs | null>(null)

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string)
  }

  // const handleChangeDateCreate = (event: SelectChangeEvent) => {
  //   setDateCreate(event.target.value)
  // }

  const statusSelection = [
    'Fullstack Development',
    'Backend Development',
    'UI Design',
  ]

  return (
    <>
      {/* Box Paper */}
      <Box>
        <Paper variant="outlined" elevation={6}>
          {/* top */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'coulm',
              padding: '24px',
            }}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <Typo
                  component="h6"
                  sx={{
                    margin: '0px',
                    fontWeight: '700',
                    lineHeight: '1.55556',
                    fontSize: '1.0625rem',
                    color: 'rgb(145, 158, 171)',
                  }}
                >
                  {' '}
                  From{' '}
                </Typo>
                <Button size="small" startIcon={<CreateIcon />}>
                  Change
                </Button>
              </Box>
              <Typo variant="subtitle2" gutterBottom>
                {' '}
                Name
              </Typo>
              <Typo variant="subtitle2" gutterBottom>
                {' '}
                Address
              </Typo>
              <Typo variant="subtitle2" gutterBottom>
                {' '}
                Phone: 09876543121
              </Typo>
            </Box>

            <Divider
              sx={{ borderStyle: 'dashed', margin: '0px 40px 0px 40px' }}
              orientation="vertical"
              flexItem
            ></Divider>

            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <Typo
                  component="h6"
                  sx={{
                    margin: '0px',
                    fontWeight: '700',
                    lineHeight: '1.55556',
                    fontSize: '1.0625rem',
                    color: 'rgb(145, 158, 171)',
                  }}
                >
                  {' '}
                  To{' '}
                </Typo>
                <Button size="small" startIcon={<AddIcon />}>
                  Add
                </Button>
              </Box>
            </Box>
          </Box>
          {/*  inputs */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: '24px',
              backgroundColor: 'rgb(244, 246, 248)',
            }}
          >
            <TextField
              label="Invoice number"
              disabled
              value="INV-17099"
              name="invoiceNumber"
              sx={{ ...flexStyle, margin: '0px' }}
            />
            <Selection
              id="status"
              labelId="status-label"
              value={status}
              onChange={handleChange}
              items={statusSelection}
              label="Status"
              style={flexStyle}
            />
            <Box component="div" sx={flexStyle}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date create"
                  value={dateCreate}
                  onChange={(newValue) => {
                    setDateCreate(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            {/* <TimePicker
              style={flexStyle}
              label="Date Create"
              value={dateCreate}
              onChange={handleChange}
            /> */}

            <Box component="div" sx={flexStyle}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Due date"
                  value={dateDue}
                  onChange={(newValue) => {
                    setDateDue(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          {/* Details */}
          <Box sx={{ padding: '24px' }}>
            <Typo
              component="h6"
              sx={{
                margin: '0px 0px 24px',
                fontWeight: 700,
                lineHeight: 1.5,
                color: 'rgb(145, 158, 171)',
              }}
            >
              Details:{' '}
            </Typo>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                >
                  <TextField
                    label="Title"
                    sx={{ ...flexStyle, margin: '0px' }}
                    size="small"
                  />
                  <TextField label="Description" sx={flexStyle} size="small" />

                  <Selection
                    id="status"
                    labelId="status-label"
                    value={status}
                    onChange={handleChange}
                    items={statusSelection}
                    label="Status"
                    style={flexStyle}
                    size="small"
                  />

                  <TextField label="Quantiy" sx={flexStyle} size="small" />
                  <TextField label="Price" sx={flexStyle} size="small" />
                  <TextField label="Total" sx={flexStyle} size="small" />
                </Box>
                <Button
                  size="small"
                  color="error"
                  startIcon={<DeleteOutlineIcon />}
                >
                  Remove
                </Button>
              </Box>
            </Box>
            <Divider
              sx={{ borderStyle: 'dashed', margin: '24px 0px' }}
              orientation="horizontal"
              flexItem
              variant="fullWidth"
            ></Divider>
            {/*  add discounr taxes */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Button
                size="small"
                sx={{ flexShrink: 0 }}
                startIcon={<AddIcon />}
              >
                Add Item
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <TextField
                  label="Discount"
                  sx={{ ...flexStyle, maxWidth: '200px' }}
                  size="small"
                />
                <TextField
                  label="Taxes"
                  sx={{ ...flexStyle, maxWidth: '200px' }}
                  size="small"
                />
              </Box>
            </Box>
            {/*  Total */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '24px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Typo
                  variant="body1"
                  sx={{ ...computationStyle, margin: '0px' }}
                  paragraph
                >
                  Subtotal :
                </Typo>
                <Typo
                  sx={{
                    ...computationStyle,
                    margin: '0px',
                    textAlign: 'right',
                    width: '120px',
                  }}
                  variant="body1"
                  paragraph
                >
                  -
                </Typo>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Typo variant="body1" sx={computationStyle} paragraph>
                  Discount
                </Typo>
                <Typo
                  variant="body1"
                  sx={{
                    ...computationStyle,
                    textAlign: 'right',
                    width: '120px',
                  }}
                  paragraph
                >
                  -
                </Typo>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Typo variant="body1" sx={computationStyle} paragraph>
                  Taxes
                </Typo>
                <Typo
                  variant="body1"
                  sx={{
                    ...computationStyle,
                    textAlign: 'right',
                    width: '120px',
                  }}
                  paragraph
                >
                  -
                </Typo>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Typo variant="h6" sx={{ marginTop: '16px' }}>
                  Total price
                </Typo>
                <Typo
                  variant="h6"
                  sx={{ marginTop: '16px', textAlign: 'right', width: '120px' }}
                >
                  -
                </Typo>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
      {/* Buttons */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        <Button variant="contained" color="info">
          Save As Draft
        </Button>
        <Button sx={{ marginLeft: '16px' }} variant="contained">
          Create & Send
        </Button>
      </Box>
    </>
  )
}

export default ProposalCreate
