import React from 'react'
import { NavLink } from 'react-router-dom'

const Link = React.forwardRef<any, any>((props, ref) => (
  <NavLink
    ref={ref}
    {...props}
    className={({ isActive }) =>
      `${props.className} ${isActive ? 'active' : ''}`
    }
    end
  >
    {props.children}
  </NavLink>
))

export default Link
