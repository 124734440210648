import React from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'

import { StorageService } from '@learnerlife/services'
import { useFirebaseApp } from '@learnerlife/contexts/FirebaseApp'
import Sidebar from './Sidebar'
import Page from './Page'

import type { IProtectedRoutes } from './types'

const ProtectedRoutes = ({ allowedUserRoles }: IProtectedRoutes) => {
  const location = useLocation()
  const { user } = useFirebaseApp()
  const hydrated = StorageService.Retrieve(true)

  const renderRoute = () => {
    if (user) {
      if (allowedUserRoles) {
        if (
          user.roles &&
          user.roles.find((role) => allowedUserRoles?.includes(role))
        ) {
          return (
            <>
              <Sidebar />
              <Page />
            </>
          )
        } else if (user.roles) {
          return (
            <Navigate
              to="/authorize"
              state={{ from: location, noRolesMatched: true }}
              replace
            />
          )
        }
        return (
          <Navigate
            to="/404"
            state={{ from: location, noRoles: true }}
            replace
          />
        )
      }
      return <Page />
    }

    if (hydrated && hydrated.sess) {
      return <Navigate to="/authorize" state={{ from: location }} replace />
    }

    return <Navigate to="/signin" state={{ from: location }} replace />
  }
  return renderRoute()
}

export default ProtectedRoutes
