import React from 'react'
import { FormControl, FormControlLabel, Switch } from '@mui/material'

import type { IFormSwitchProps } from './types'

const FormSwitch = ({ label, checked }: IFormSwitchProps) => {
  return (
    <FormControl fullWidth variant="outlined" sx={{ alignItems: 'flex-start' }}>
      <FormControlLabel
        control={<Switch {...{ defaultChecked: checked }} />}
        label={label}
      />
    </FormControl>
  )
}

export default FormSwitch
