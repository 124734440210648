import type { TIcons } from './types'

const iconMappings: TIcons = {
  dashboard: 'Dashboard',
  agent: 'AssignmentInd',
  school: 'School',
  list: 'List',
} as const

export default iconMappings
