/* eslint-disable curly */
import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import type { IFormSelect } from './types'

const FormSelect = ({
  name,
  label,
  options,
  selected,
  onSelect,
}: IFormSelect) => {
  const selectId = `formselect-${name}`

  const handleChange = (event: SelectChangeEvent) => {
    if (onSelect) onSelect(event.target.value as string)
  }

  function renderOptions() {
    if (options.length === 0) return null
    return options.map((option, idx) => {
      return (
        <MenuItem key={`${selectId}-option-${idx}`} value={option.value}>
          {option.label}
        </MenuItem>
      )
    })
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id={`${selectId}_label`}>{label}</InputLabel>
        <Select
          labelId={`${selectId}_label`}
          id={selectId}
          value={selected}
          label={label}
          onChange={handleChange}
        >
          {renderOptions()}
        </Select>
      </FormControl>
    </Box>
  )
}

export default FormSelect
