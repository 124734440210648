import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Card,
  Box,
  Typography as Typo,
  Button,
  Stack,
  Snackbar,
  TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { PageDefaultLayout } from '@learnerlife/components/common/Page'

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required'),
  message: yup.string().required('Message is required'),
})

const ContactUs = () => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })
  return (
    <PageDefaultLayout>
      <Box
        sx={{
          '.app-logo': {
            lineHeight: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '90px',
            '& img': {
              height: '100%',
            },
          },
        }}
      >
        <figure className="app-logo">
          <img src="/images/ll_logo.png" alt="learner life logo" />
        </figure>
      </Box>
      <Typo variant="h3">Send us your inquiries or request</Typo>
      <form onSubmit={formik.handleSubmit}>
        <Stack>
          <TextField
            label="Name"
            id="name"
            name="name"
            placeholder="eg. John Doe"
            sx={{ marginTop: '1rem' }}
          />
          <TextField
            label="Email"
            id="email"
            name="email"
            placeholder="johndoe@gmail.com"
            sx={{ marginTop: '1rem' }}
          />
          <TextField
            label="Message"
            id="message"
            name="message"
            placeholder="Write something.."
            multiline
            rows={4}
            sx={{ marginTop: '1rem' }}
          />
          <Button
            variant="contained"
            sx={{ marginTop: '1rem', height: '45px' }}
          >
            Send message
          </Button>
        </Stack>
      </form>
    </PageDefaultLayout>
  )
}

export default ContactUs
