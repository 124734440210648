import React from 'react'
import Box from '@mui/material/Box'
import Typo from '@mui/material/Typography'

import Product from '@learnerlife/components/Product'

import { useAgent } from '.'

const AgentProgrammes = () => {
  const agent = useAgent()

  React.useEffect(() => {
    agent.actions.updatePageName('Programmes Available')
  }, [])

  return (
    <Box>
      <Product>
        <Product.Item
          id="school-programmes-1"
          name="NUS Business School: Programme 1"
          category="English Primary National Curriculum"
          cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
          slug="/programmes/nus-business-school-programme-1"
        />
        <Product.Item
          id="school-programmes-2"
          name="SOTA: Programme 2"
          category="Degree Honors"
          cover="https://www.sota.edu.sg/images/default-source/default-album/branding-mobile.jpg"
          slug="/programmes/sota-programme-1"
        />
        <Product.Item
          id="school-programmes-3"
          name="NTU: Programme 3"
          category="Degree Honors"
          cover="https://www.ntu.edu.sg/images/default-source/ogem/dsc_754109e1aa2b-1dd5-4d48-935b-dfec268c99b8.jpg?Status=Master&sfvrsn=683bc53b_3"
        />
        <Product.Item
          id="school-programmes-3"
          name="SOTA: Programme 3"
          category="English Primary National Curriculum"
          cover="https://www.sota.edu.sg/images/default-source/default-album/branding-mobile.jpg"
        />
        <Product.Item
          id="school-programmes-3"
          name="NUS Business School: Programme 3"
          category="English Primary National Curriculum"
          cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
        />

        <Product.Item
          id="school-programmes-3"
          name="NUS Business School: Programme 3"
          category="English Primary National Curriculum"
          cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
        />
        <Product.Item
          id="school-programmes-1"
          name="NUS Business School: Programme 1"
          category="English Primary National Curriculum"
          cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
        />
        <Product.Item
          id="school-programmes-2"
          name="SOTA: Programme 2"
          category="Degree Honors"
          cover="https://www.sota.edu.sg/images/default-source/default-album/branding-mobile.jpg"
        />
        <Product.Item
          id="school-programmes-3"
          name="NTU: Programme 3"
          category="Degree Honors"
          cover="https://www.ntu.edu.sg/images/default-source/ogem/dsc_754109e1aa2b-1dd5-4d48-935b-dfec268c99b8.jpg?Status=Master&sfvrsn=683bc53b_3"
        />
        <Product.Item
          id="school-programmes-3"
          name="SOTA: Programme 3"
          category="English Primary National Curriculum"
          cover="https://www.sota.edu.sg/images/default-source/default-album/branding-mobile.jpg"
        />
        <Product.Item
          id="school-programmes-3"
          name="NUS Business School: Programme 3"
          category="English Primary National Curriculum"
          cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
        />

        <Product.Item
          id="school-programmes-3"
          name="NUS Business School: Programme 3"
          category="English Primary National Curriculum"
          cover="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLk2nqGRgYQiT0JUSraAhUmwQWUBAS2if0ew&usqp=CAU"
        />
      </Product>
    </Box>
  )
}

export default AgentProgrammes
