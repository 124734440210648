import React from 'react'
import Box from '@mui/material/Box'

const ProposalxStudent = () => {
  return (
    <Box sx={{ height: '100vh' }}>Proposal Shared Detail : Student view</Box>
  )
}

export default ProposalxStudent
