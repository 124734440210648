import React from 'react'
import { FormControl, Typography as Typo, Box } from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import type { IWyswygProps } from './types'

const Wyswyg = ({
  value,
  onChange,
  label,
  placeholder = 'Write something awesome...',
}: IWyswygProps) => {
  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <FormControl
      fullWidth
      variant="outlined"
      sx={{ alignItems: 'flex-start', paddingTop: '1rem' }}
    >
      {label && <Typo variant="subtitle2">{label}</Typo>}
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
          marginTop: '0.5rem',
          borderRadius: '8px',
          border: '1px solid rgba(145, 158, 171, 0.32)',
          '& .ql-container.ql-snow': {
            fontFamily: 'inherit',
            borderColor: 'transparent',
            lineHeight: 1.5,
            fontSize: '1rem',
            fontWeight: '400',
          },
          '& .ql-editor': {
            minHeight: '200px',
            maxHeight: '640px',
            '&.ql-blank': {
              '&:before': {
                fontStyle: 'normal',
                color: 'rgb(145, 158, 171)',
              },
            },
          },
          '& .ql-toolbar': {
            textAlign: 'left',
            '&.ql-snow': {
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderImage: 'initial',
              borderBottom: '1px solid rgba(145, 158, 171, 0.32)',
            },
          },
          '& .ql-toolbar.ql-snow .ql-formats:not(:last-of-type)': {
            marginRight: '1rem',
          },
        }}
      >
        <ReactQuill
          theme="snow"
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          modules={{
            toolbar: [
              [{ size: [] }],
              [{ header: '1' }, { header: '2' }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
              ],
              ['link', 'image', 'video'],
              ['clean'],
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
          }}
          formats={[
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'video',
          ]}
        />
      </Box>
    </FormControl>
  )
}

export default Wyswyg
