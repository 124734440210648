import { AnyAction } from 'redux'

import ProgrammesConstants from '../constants/programmes.constants'
import type { IProgrammesState } from '../types/programmes.types'

const initialState: IProgrammesState = {
  programmes: [],
  status: {
    fetching: false,
    success: false,
    error: null,
  },
}

export default function Programmes(state = initialState, action: any) {
  switch (action.type) {
    case ProgrammesConstants.PROGRAMMES_GET_LIST:
      return {
        ...state,
        status: {
          fetching: true,
          success: false,
          error: null,
        },
      }
    case ProgrammesConstants.PROGRAMMES_GET_LIST_SUCCESS:
      return {
        ...state,
        programmes: action.payload,
        status: {
          fetching: false,
          success: true,
          error: null,
        },
      }
    case ProgrammesConstants.PROGRAMMES_GET_LIST_FAIL:
      return {
        ...state,
        programmes: [],
        status: {
          fetching: false,
          success: false,
          error: {
            code: action.payload.code,
            message: action.payload.message,
          },
        },
      }
    default:
      return state
  }
}
