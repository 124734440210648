import React from 'react'
import { alpha } from '@mui/material/styles'
import { Avatar } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typo from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { useFirebaseApp } from '../../contexts/FirebaseApp'

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { user, actions } = useFirebaseApp()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSignOut = async () => {
    try {
      await actions.signout()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        height: 92,
        bgcolor: (theme) => alpha('#ffffff', 0.8),
        boxShadow: 'none',
        zIndex: 1000,
        backdropFilter: 'blur(5px)',
        WebkitBackdropFilter: 'blur(5px)',
        width: 'calc(100% - 240px)',
      }}
    >
      <Toolbar
        sx={{
          alignItems: 'center',
          flexGrow: 1,
          justifyContent: 'flex-end',
        }}
      >
        <Typo variant="h5" sx={{ flexGrow: 1 }} color="#000000"></Typo>
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar
              alt={user?.currentUser?.displayName || ''}
              src={user?.currentUser?.photoURL || ''}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
