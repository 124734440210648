import React from 'react'
import { List, ListSubheader } from '@mui/material'

import { useFirebaseApp } from '@learnerlife/contexts/FirebaseApp'
import NavItem from './NavItem'

const Nav = () => {
  const { user } = useFirebaseApp()

  // This is only a temp solution for rendering the menus by role, this should come from the appconfig so it'll be easier to manage.
  function renderMenusByRole() {
    if (user?.roles) {
      if (user.roles.includes('learner:agent')) {
        return [
          <NavItem to="/profile" icon="agent" key="sidebar-menu-1">
            Agent
          </NavItem>,
          <NavItem to="/programmes" icon="school" key="sidebar-menu-2">
            Programmes
          </NavItem>,
          <NavItem
            key="sidebar-menu-3"
            to="/proposals"
            icon="school"
            items={[
              {
                content: 'List of Proposals',
                to: '/proposals',
              },
              {
                content: 'Create New Proposal',
                to: '/proposal/new',
              },
            ]}
          >
            Proposals
          </NavItem>,
          <NavItem key="sidebar-menu-4" to="/applications" icon="school">
            Applications
          </NavItem>,
        ]
      }
      if (user.roles.includes('learner:school')) {
        return [
          <NavItem to="/profile" icon="agent">
            School
          </NavItem>,
          <NavItem
            to="/programmes"
            icon="school"
            items={[
              {
                content: 'List of Programmes',
                to: '/programmes',
              },
              {
                content: 'Create New Programme',
                to: '/programme/new',
              },
            ]}
          >
            Programmes
          </NavItem>,
          <NavItem
            to="/applications"
            icon="school"
            items={[
              {
                content: 'List of Applications',
                to: '/applications',
              },
            ]}
          >
            Applications
          </NavItem>,
        ]
      }
      // Default or role: learner:user menus
      return [
        <NavItem to="/profile" icon="agent">
          Profile
        </NavItem>,
        <NavItem
          to="/proposals"
          icon="school"
          items={[
            {
              content: 'List of Proposals',
              to: '/proposals',
            },
            {
              content: 'Create New Proposal',
              to: '/proposal/new',
            },
          ]}
        >
          Proposals
        </NavItem>,
        <NavItem
          to="/applications"
          icon="school"
          items={[
            {
              content: 'List of Applications',
              to: '/applications',
            },
          ]}
        >
          Applications
        </NavItem>,
      ]
    }
    return null
  }

  return (
    <nav className="app-sidebar_nav">
      <List sx={{ width: '100%' }} aria-labelledby="sidebar-manage">
        <ListSubheader sx={{ background: 'none' }} id="sidebar-manage">
          Manage
        </ListSubheader>
        {renderMenusByRole()}
      </List>
    </nav>
  )
}

export default Nav
