/* eslint-disable curly */
import React from 'react'
import { useLocation } from 'react-router-dom'

import { usePage } from './Page'

export interface IPageUpdate {
  parentPageName?: string | null
  pageName?: string | null
  remove?: boolean
}

const PageUpdate = ({
  parentPageName,
  pageName,
  remove = false,
}: IPageUpdate) => {
  const page = usePage()

  React.useEffect(() => {
    if (remove !== undefined && remove === true) {
      page.actions.updatePageContent(null)
    } else {
      const value = {
        parentPageName,
        pageName,
      }
      if (value.parentPageName === undefined) delete value.parentPageName
      if (value.pageName === undefined) delete value.pageName
      page.actions.updatePageContent(value)
    }
  }, [parentPageName, pageName, remove])

  return null
}

export default PageUpdate
